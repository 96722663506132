import React from "react";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";

import CommodityApi from "../api/Commodity.api";
import SimpleListName from "../components/SimpleListName";
import { Commodity } from "../types";
import { HttpError } from "../types/http-error";
import toast from "react-hot-toast";
import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";

export default function CommodityList() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>("");

  const { mutateAsync: createCommodity, isLoading } = CommodityApi.useSave({
    name,
  });

  const handleCreate = async () => {
    try {
      const result = await createCommodity();
      navigate(`/commodities/${result.id}`);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to create Commodity");
      }
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "1rem"}}>
      <Grid container spacing={2}>
        <Grid item xs={9} sm={8}>
          <Typography
            variant={isSmallScreen ? "h4" : "h2"}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            Commodities
          </Typography>
        </Grid>
        <Grid item xs={3} sm={4} sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end"}}>
          <Grid
            container
            direction="row"
            justifyContent={"flex-end"}
            alignItems="center"
          >
            <Button
              size={"large"}
              variant="contained"
              color="secondary"
              onClick={() => setOpenModal(true)}
            >
              <AddIcon />
            </Button>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
              <Box
                sx={{
                  position: "absolute" as "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <Typography variant={"h6"}>Create Commodity</Typography>
                <TextField
                  variant={"outlined"}
                  label={"Name"}
                  value={name}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setName(event.target.value);
                  }}
                  fullWidth
                />
                <Button
                  disabled={isLoading}
                  onClick={handleCreate}
                  fullWidth
                  variant={"contained"}
                  color={"primary"}
                >
                  Create
                </Button>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Grid>
      <SimpleListName
        useList={CommodityApi.useInfiniteList}
        useDelete={CommodityApi.useDelete}
        baseUrl="commodities"
        headers={[
          {
            key: "name",
            label: "Name",
            getValue: (obj: Commodity) => obj.name,
          },
        ]}
      />
    </Box>
  );
}
