import React from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import PersonApi from "../api/Person.api";
import SimpleListName from "../components/SimpleListName";
import IndexHeaderButton from "../ui/IndexHeaderButton";
import { Person } from "../types";
import { Box, Grid, Typography } from "@mui/material";

export default function PeopleList() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  let headers = [
    {
      key: "name",
      label: "Name",
      getValue: (obj: Person) => `${obj?.firstName} ${obj?.lastName}`,
    },
    {
      key: "regions",
      label: "Regions",
      getValue: (obj: Person) =>
        obj?.regions ? obj.regions?.map(region => region.name).join(", ") : "",
    },
    {
      key: "companies",
      label: "Companies",
      getValue: (obj: Person) =>
        obj.companies
          ? obj.companies?.map(company => company.name).join(", ")
          : "",
    },
  ];
  if (!isSmallScreen) {
    headers = [...headers.slice(0, 1), {
      key: "position",
      label: "Position",
      getValue: (obj: Person) => obj?.position,
    }, ...headers.slice(1)];
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Grid container spacing={2}>
        <Grid item xs={9} sm={8}>
          <Typography
            variant={isSmallScreen ? "h4" : "h2"}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            People
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sm={4}
          sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}
        >
          <IndexHeaderButton
            handleCreate={() => {
              navigate("/people/create");
            }}
          />
        </Grid>
      </Grid>
      <SimpleListName
        useList={PersonApi.useInfiniteList}
        useDelete={PersonApi.useDelete}
        baseUrl="people"
        headers={headers}
      />
    </Box>
  );
}
