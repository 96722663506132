import * as Sentry from "@sentry/react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import VarietyApi from "../api/Variety.api";
import { Commodity, Company, Variety, VarietyStatus } from "../types";
import CompanySelect from "./CompanySelect";
import AssociatedCommodities from "./AssociatedCommodities";
import { HttpError } from "../types/http-error";
import toast from "react-hot-toast";

export default function VarietyForm({
  variety,
  refetch,
}: {
  variety: Variety;
  refetch: () => void;
}) {
  const navigate = useNavigate();

  // name company, status

  const [name, setName] = React.useState<string>(variety?.name || "");
  const [company, setCompany] = React.useState<Company | undefined>(
    variety?.company
  );
  const [dateReceived, setDateReceived] = React.useState<Dayjs | null>(
    variety?.dateReceived ? dayjs(variety.dateReceived, "YYYY-MM-DD") : null
  );
  const [resistance, setResistance] = React.useState<string>(
    variety?.resistance || ""
  );
  const [status, setStatus] = React.useState<VarietyStatus | undefined>(
    variety?.status
  );
  const [selectedCommodities, setSelectedCommodities] = React.useState<
    Commodity[]
  >(variety?.commodities || []);

  const { mutateAsync: removeVarietyCombination } =
    VarietyApi.useRemoveCombination(variety.id);

  const { mutateAsync, isLoading } = VarietyApi.useSave({
    ...variety,
    name,
    dateReceived: dateReceived ? dateReceived.format("YYYY-MM-DD") : undefined,
    experimental: variety?.experimental || false,
    resistance,
    status,
    commodityIds: selectedCommodities.map(c => c.id),
    companyId: company?.id,
  });

  const onSave = async () => {
    try {
      const res = await mutateAsync();
      navigate(`/varieties/${res.id}`);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to create Person");
      }
    }
  };

  const handleDelete = async () => {};

  const handleRemoveExperimentalVariety = async (id: string | number) => {
    try {
      await removeVarietyCombination(id);
      refetch();
      toast.success("Successfully removed.");
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          {variety?.id ? "Edit Variety" : "Create Variety"}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Commercial Name"
          value={name}
          onChange={event => setName(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePicker
          label="Date Received"
          value={dateReceived}
          onChange={(newValue: Dayjs | null) => setDateReceived(newValue)}
          sx={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CompanySelect
          company={company}
          setCompany={setCompany}
          label="Supplier"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel>Status</InputLabel>
          <Select
            sx={{ width: "100%" }}
            value={status}
            onChange={(event: SelectChangeEvent) =>
              setStatus(event.target.value as VarietyStatus)
            }
            label={"Status"}
          >
            <MenuItem value={undefined}>None</MenuItem>
            <MenuItem value={"screening"}>Screening</MenuItem>
            <MenuItem value={"product_development"}>
              Product Development
            </MenuItem>
            <MenuItem value={"sale_trials"}>Sale Trials</MenuItem>
            <MenuItem value={"commercial"}>Commercial</MenuItem>
            <MenuItem value={"drop"}>Drop</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Resistance"
          value={resistance}
          onChange={event => setResistance(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AssociatedCommodities
          selectedCommodities={selectedCommodities}
          setSelectedCommodities={setSelectedCommodities}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Experimental Vareities
        </Typography>
        {/*  TODO list off all the experimental varieties iwth delete button */}
        <List>
          {variety?.experimentalVarieties &&
            variety?.experimentalVarieties.map(
              (experimentalVariety: Variety) => (
                <ListItemButton
                  alignItems={"flex-start"}
                  key={experimentalVariety.id}
                >
                  <ListItemText primary={experimentalVariety.name} />
                  <ListItemSecondaryAction>
                    <Button
                      onClick={() =>
                        handleRemoveExperimentalVariety(experimentalVariety.id)
                      }
                    >
                      Remove from list
                    </Button>
                  </ListItemSecondaryAction>
                </ListItemButton>
              )
            )}
        </List>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Button
          fullWidth
          component={Link}
          to="/varieties"
          variant="contained"
          color="info"
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          component={Link}
          to={`/varieties/${variety.id}/combine`}
        >
          Combine
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Button
          disabled={isLoading}
          fullWidth
          variant="contained"
          color="primary"
          onClick={onSave}
        >
          Save
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Button
          fullWidth
          variant={"contained"}
          color={"error"}
          onClick={handleDelete}
        >
          Delete
        </Button>
      </Grid>
    </Grid>
  );
}
