import React from "react";
import * as Sentry from "@sentry/react";
import {
  Area,
  Commodity,
  Company,
  CulturalPractice,
  PlotType,
  Ranch,
  Region,
  Trial,
  TrialVariety,
} from "../../types";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import RanchCreateableSelect from "../RanchCreatableSelect";
import RegionCreateableSelect from "../RegionCreatableSelect";
import AreaCreatableSelect from "../AreaCreatableSelect";
import CommodityCreateableSelect from "../CommodityCreatableSelect";
import CompanyCreateableSelect from "../CompanyCreatableSelect";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Link, useNavigate } from "react-router-dom";
import PlotGrid from "../PlotGrid";
import { isNumber, isString } from "lodash";
import TrialVarietyApi from "../../api/TrialVariety.api";
import FarmMap from "./FarmMap";
import TrialApi from "../../api/Trial.api";
import toast from "react-hot-toast";
import { HttpError } from "../../types/http-error";
import RecommendHarvestDate from './RecommendHarvestDate'
import EvaluationModal from './EvaluationModal'
import { useTrialContext } from '../../contexts/Trial.context'

export default function TrialForm({
  trial,
  trialVarieties,
}: {
  trial: Trial;
  trialVarieties: TrialVariety[];
}) {
  const navigate = useNavigate();
  const { setSelectedTrialVariety } = useTrialContext();
  const { mutateAsync: deleteTrialVariety } = TrialVarietyApi.useDelete();
  const { mutateAsync: createTrialVariety } = TrialVarietyApi.useSave();

  const [archiveOverride, setArchiveOverride] = React.useState<boolean>(
    trial.archiveOverride
  );
  const [autoArchive, setAutoArchive] = React.useState<boolean>(
    trial.autoArchive
  );
  const [grower, setGrower] = React.useState<Company | undefined>(trial.grower);
  const [subGrower, setSubGrower] = React.useState<Company | undefined>(
    trial.subGrower
  );
  const [shipper, setShipper] = React.useState<Company | undefined>(
    trial.shipper
  );
  const [ranch, setRanch] = React.useState<Ranch | undefined>(trial.ranch);
  const [lat, setLat] = React.useState<string>(trial?.lat || "");
  const [lng, setLng] = React.useState<string>(trial?.lng || "");
  const [lot, setLot] = React.useState<string>(trial.lot || "");
  const [region, setRegion] = React.useState<Region | undefined>(trial.region);
  const [area, setArea] = React.useState<Area | undefined>(trial.area);
  const [commodity, setCommodity] = React.useState<Commodity | undefined>(
    trial.commodity
  );
  const [wetDate, setWetDate] = React.useState<Dayjs | null>(
    trial.wetDate ? dayjs(trial.wetDate) : null
  );
  const [transplantDate, setTransplantDate] = React.useState<Dayjs | null>(
    trial.transplantDate ? dayjs(trial.transplantDate) : null
  );
  const [estHarvestDate, setEstHarvestDate] = React.useState<Dayjs | null>(
    trial.estHarvestDate ? dayjs(trial.estHarvestDate) : null
  );
  const [culturalPractice, setCulturalPractice] =
    React.useState<CulturalPractice>(trial.culturalPractice || "conventional");
  const [salesTeam, setSalesTeam] = React.useState<boolean>(
    trial.salesTeam || false
  );
  const [cleaned, setCleaned] = React.useState<boolean>(trial.cleaned || false);
  const [comments, setComments] = React.useState<string>(trial.comments || "");
  const [plotCols, setPlotCols] = React.useState<number | string>(
    trial.plotCols || ""
  );
  const [plotRows, setPlotRows] = React.useState<number | string>(
    trial.plotRows || ""
  );
  const [plotType, setPlotType] = React.useState<PlotType>(
    trial.plotType || "snake"
  );

  const [zoom, setZoom] = React.useState<number>(trial?.zoom || 0);

  const { mutateAsync: saveTrial, isLoading: isSavingTrial } = TrialApi.useSave(
    {
      ...trial,
      archiveOverride,
      autoArchive,
      areaId: area?.id,
      cleaned,
      comments,
      commodityId: commodity?.id,
      culturalPractice,
      estHarvestDate: estHarvestDate?.format("YYYY-MM-DD"),
      growerId: grower?.id,
      lat,
      lng,
      lot,
      plotCols: isString(plotCols) ? 0 : plotCols,
      plotRows: isString(plotRows) ? 0 : plotRows,
      plotType,
      ranchId: ranch?.id,
      regionId: region?.id,
      salesTeam,
      shipperId: shipper?.id,
      subGrowerId: subGrower?.id,
      transplantDate: transplantDate?.format("YYYY-MM-DD"),
      wetDate: wetDate?.format("YYYY-MM-DD"),
      zoom,
    }
  );

  const handleSave = async () => {
    try {
      await saveTrial();
      toast.success("Saved Trial successfully");
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to save Trial");
      }
    }
  };

  const { mutateAsync: deleteTrial, isLoading: isDeletingTrial } =
    TrialApi.useDelete(trial.id);

  const handleDelete = async () => {
    try {
      await deleteTrial();
      navigate("/trials");
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to delete Trial");
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={5}>
        <Typography variant={"h2"}>Trial</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={7}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: "1rem",
        }}
      >
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={archiveOverride}
                  onChange={e => setArchiveOverride(e.target.value === "true")}
                  color={"primary"}
                  disabled={trial.archive}
                />
              }
              label="Archive"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={autoArchive}
                  onChange={e => setAutoArchive(e.target.value === "true")}
                  color={"primary"}
                />
              }
              label="Auto Archive"
            />
          </FormGroup>
        </FormControl>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Button
            variant={"contained"}
            component={Link}
            to={{ pathname: `/trials/plot_report/${trial.id}` }}
            sx={{ color: "#fff !important" }}
          >
            Plot Report
          </Button>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Button
            variant={"contained"}
            component={Link}
            sx={{ color: "#fff !important" }}
            to={{ pathname: `/trials/evaluation_report/${trial.id}` }}
          >
            Trial Report
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          label={"Name"}
          value={trial.name}
          disabled={true}
          variant={"outlined"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CompanyCreateableSelect
          label={"Grower"}
          company={grower}
          setCompany={setGrower}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CompanyCreateableSelect
          label={"SubGrower"}
          company={subGrower}
          setCompany={setSubGrower}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CompanyCreateableSelect
          label={"Shipper"}
          company={shipper}
          setCompany={setShipper}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RanchCreateableSelect
          setRanch={setRanch}
          ranch={ranch}
          label={"Ranch"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          label={"Lot #"}
          value={lot}
          onChange={e => setLot(e.target.value)}
          variant={"outlined"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RegionCreateableSelect
          setRegion={setRegion}
          region={region}
          label={"Region"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AreaCreatableSelect setArea={setArea} area={area} label={"Area"} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CommodityCreateableSelect
          setCommodity={setCommodity}
          commodity={commodity}
          label={"Commodity"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DatePicker
          label={"Wet Date"}
          sx={{ width: "100%" }}
          value={wetDate}
          onChange={newValue => setWetDate(newValue)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DatePicker
          label={"Transplant Date"}
          sx={{ width: "100%" }}
          value={transplantDate}
          onChange={newValue => setTransplantDate(newValue)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DatePicker
          label={"Est. Harvest Date"}
          sx={{ width: "100%" }}
          value={estHarvestDate}
          onChange={newValue => setEstHarvestDate(newValue)}
        />
        <RecommendHarvestDate trial={trial} />
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <FormControl>
          <FormLabel id={"culturalPractice"}>Cultural Practice</FormLabel>
          <RadioGroup
            aria-labelledby={"culturalPractice"}
            name={"culturalPractice"}
            value={culturalPractice}
            onChange={e =>
              setCulturalPractice(e.target.value as CulturalPractice)
            }
          >
            <FormControlLabel
              value={"conventional"}
              control={<Radio />}
              label={"Conventional"}
            />
            <FormControlLabel
              value={"organic"}
              control={<Radio />}
              label={"Organic"}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <FormControl>
          <FormLabel id={"salesTeam"}>Sales Team</FormLabel>
          <RadioGroup
            aria-labelledby={"salesTeam"}
            name={"salesTeam"}
            value={salesTeam}
            onChange={e => setSalesTeam(e.target.value === "true")}
          >
            <FormControlLabel
              value={"true"}
              control={<Radio />}
              label={"PD Trial"}
            />
            <FormControlLabel
              value={"false"}
              control={<Radio />}
              label={"Sales Trial"}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <FormControl>
          <FormLabel id={"cleaned"}>Cleaned</FormLabel>
          <RadioGroup
            aria-labelledby={"cleaned"}
            name={"cleaned"}
            value={cleaned?.toString()}
            onChange={e => setCleaned(e.target.value === "true")}
          >
            <FormControlLabel
              value={"true"}
              control={<Radio />}
              label={"Cleaned"}
            />
            <FormControlLabel
              value={"false"}
              control={<Radio />}
              label={"Not Cleaned"}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          placeholder="Comments"
          variant="outlined"
          value={comments}
          onChange={e => setComments(e.target.value)}
          label={"Comments"}
          fullWidth
          multiline
          rows={4}
        />
      </Grid>
      <Grid item xs={12}>
        <EvaluationModal
          trial={trial}
          plotType={plotType}
          plotRows={isNumber(plotRows) ? plotRows : 0}
          plotCols={isNumber(plotCols) ? plotCols : 0}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          value={plotRows}
          onChange={event => {
            const value = event.target.value;
            const parsedValue = parseInt(value);
            if (!isNaN(parsedValue) && parsedValue >= 0) {
              setPlotRows(parseInt(value));
            } else {
              setPlotRows("");
            }
          }}
          label="Plot Rows"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          value={plotCols}
          onChange={event => {
            const value = event.target.value;
            const parsedValue = parseInt(value);
            if (!isNaN(parsedValue) && parsedValue >= 0) {
              setPlotCols(parseInt(value));
            } else {
              setPlotCols("");
            }
          }}
          label="Plot Columns"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={plotType === "normal"}
                onChange={event =>
                  setPlotType(event.target.checked ? "normal" : "snake")
                }
              />
            }
            label="Normal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={plotType === "snake"}
                onChange={event =>
                  setPlotType(event.target.checked ? "snake" : "normal")
                }
              />
            }
            label="Snake"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <PlotGrid
          createTypeVariety={createTrialVariety}
          deleteTypeVariety={deleteTrialVariety}
          parentId={trial?.id}
          typeVarieties={trialVarieties}
          grandParentTypeVariety={"trial"}
          plotType={plotType}
          plotRows={isNumber(plotRows) ? plotRows : 0}
          plotCols={isNumber(plotCols) ? plotCols : 0}
          setSelectedTrialVariety={setSelectedTrialVariety}
        />
      </Grid>
      <Grid item xs={12}>
        <FarmMap
          lat={lat}
          setLat={setLat}
          lng={lng}
          setLng={setLng}
          zoom={zoom}
          setZoom={setZoom}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Button
          disabled={isSavingTrial}
          variant={"contained"}
          fullWidth
          onClick={handleSave}
        >
          Save
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Button
          disabled={isDeletingTrial}
          variant={"contained"}
          color={"error"}
          fullWidth
          onClick={handleDelete}
        >
          Delete
        </Button>
      </Grid>
    </Grid>
  );
}
