import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import * as Sentry from "@sentry/react";

import VarietyApi from "../api/Variety.api";
import AlgoliaSearchBox from "../components/AlgoliaSearchBox";
import AlgoliaVarietyCombine from "../components/AlgoliaVarietyCombine";

import { liteClient } from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch";
import { Button, Box, Grid, Typography } from "@mui/material";

const searchClient = liteClient(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID || "",
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY || ""
);

export default function VarietyCombine() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { id } = useParams();

  const { data: variety } = VarietyApi.useDetail(id);
  const [checked, setChecked] = React.useState<{ [key: string]: boolean }>({});

  const { mutateAsync } = VarietyApi.useCombine(id);

  const handleCombine = async () => {
    try {
      await mutateAsync(Object.keys(checked).filter(key => checked[key]));
      navigate(`/varieties/${id}`);
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem"}}>
      <Grid container spacing={2}>
        <Grid item xs={9} sm={8}>
          <Typography
            variant={isSmallScreen ? "h4" : "h2"}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            Variety {variety?.name} Combine
          </Typography>
        </Grid>
        <Grid item xs={3} sm={4} sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end"}}>
          <Button variant="contained" onClick={handleCombine}>Combine</Button>
        </Grid>
      </Grid>
      <InstantSearch
        searchClient={searchClient}
        indexName={`Variety_${process.env.REACT_APP_ENV}`}
      >
        <AlgoliaSearchBox />
        <AlgoliaVarietyCombine
          variety={variety}
          selectedItems={checked}
          setSelectedItems={setChecked}
        />
      </InstantSearch>
    </Box>
  );
}
