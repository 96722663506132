import * as Sentry from "@sentry/react";
import { Commodity, Label, Tag } from "../../types";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import CommodityApi from "../../api/Commodity.api";
import LabelApi from "../../api/Label.api";
import { HttpError } from "../../types/http-error";
import toast from "react-hot-toast";

export default function CommodityLabelForm({
  commodity,
  label,
}: {
  commodity: Commodity;
  label: Label;
}) {
  const { mutateAsync: removeLabel, isLoading: removingLabelIsLoading } = LabelApi.useRemoveLabelFromCommodity({
    commodityId: commodity.id,
  });
  const { data: selectedTags } = CommodityApi.useTags({
    commodityId: commodity.id,
    labelId: label.id,
  });
  const { mutateAsync: addTag } = CommodityApi.useAddTag({
    commodityId: commodity.id,
    labelId: label.id,
  });
  const { mutateAsync: removeTag } = CommodityApi.useRemoveTag({
    commodityId: commodity.id,
    labelId: label.id,
  });

  const commodityTagIds = selectedTags ? selectedTags.map(t => t.id) : [];

  const handleTagCheck =
    (tag: Tag) => async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        if (event.target.checked) {
          await addTag({ tagId: tag.id });
        } else {
          await removeTag({ tagId: tag.id });
        }
        toast.success("Successfully saved.");
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        if (error instanceof HttpError && error.message) {
          toast.error(error.message?.split(",").join("\n"));
        } else {
          toast.error("Failed to save tag state");
        }
      }
    };

  const handleRemoveLabel = (label: Label) => async () => {
    try {
      await removeLabel({ labelId: label.id });
      toast.success("Successfully removed.");
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to remove label");
      }
    }
  };

  return (
    <Box  sx={{ height: "100%" }}>
      <Typography variant={"h3"}>{label.name}</Typography>
      <FormGroup>
        {label.tags
          .sort((a, b) => a.location - b.location)
          .map(tag => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={commodityTagIds.includes(tag.id)}
                  onChange={handleTagCheck(tag)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={tag.name}
              key={tag.id}
            />
          ))}
      </FormGroup>
      <Button disabled={removingLabelIsLoading} variant={"outlined"} color="error" onClick={handleRemoveLabel(label)}>
        Remove Label
      </Button>
    </Box>
  );
}
