import { Box, Button } from "@mui/material";
import React from "react";
import Filters from "../components/reports/Filters";
import { useSearchParams } from "react-router-dom";
import { ReportCompareProvider } from "../contexts/ReportCompare.context";
import ReportCompareHeader from "../components/reports/ReportCompareHeader";
import EvaluationColumns from "../components/reports/EvaluationColumns";
import FilterListIcon from "@mui/icons-material/FilterList";
import ScatterPlot from "../components/reports/ScatterPlot";

export default function ReportsCompare() {
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const varietyIdParams = searchParams.get("variety_ids");
  const varietyIds = varietyIdParams ? varietyIdParams.split(",") : [];

  const [heightOfScatterPlot, setHeightOfScatterPlot] = React.useState(220);
  React.useEffect(() => {
    function handleResize() {
      if (window.innerHeight < 400) {
        setHeightOfScatterPlot(220);
      } else {
        const height = Math.round(window.innerHeight / 1.7);
        setHeightOfScatterPlot(height);
      }
    }
    window.addEventListener("resize", handleResize);
  });

  return (
    <ReportCompareProvider varietyIds={varietyIds}>
      <>
        <Filters showFilters={showFilters} setShowFilters={setShowFilters} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <ReportCompareHeader />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant={"outlined"}
            color={"primary"}
            size={"large"}
            onClick={() => setShowFilters(true)}
          >
            <FilterListIcon />
          </Button>
        </Box>
        <Box sx={{ flex: 1, height: heightOfScatterPlot }}>
          <ScatterPlot />
        </Box>
        <Box sx={{ flex: 1 }}>
          <EvaluationColumns reportType={"multiple"} />
        </Box>
      </>
    </ReportCompareProvider>
  );
}
