import React from "react";
import { Criterium, Subcriterium } from "../../types";
import { Button,IconButton, Box, Paper, TextField, FormGroup, FormControlLabel, Switch } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SubcriteriumApi from "../../api/Subcriterium.api";
import DragAndDropList from "../DragAndDropList";
import SubcriteriumItem, {
  DragPreview as DragPreviewSubcriterium,
} from "./SubcriteriumItem";
import { useCommodityContext } from "../../contexts/Commodity.context";
import { useDebounce } from "../../utils";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LinkMeasurementsToCriteria from './LinkMeasurementsToCriteria'

export default function CriteriumForm({
  // criteriumId,
  item,
}: {
  // criteriumId: number;
  item: Criterium;
}) {
  const {
    subcriteria,
    setSubcriteria,
    handleSaveCriterium,
    handleDeleteCriterium,
    handleSaveSubcriterium,
  } = useCommodityContext();
  const criteriumId = item.id;
  const { data: subcriteriaData, isFetched } =
    SubcriteriumApi.useList(criteriumId);

  const [showSubcriterium, setShowSubcriterium] = React.useState<boolean>(false)
  const [name, setName] = React.useState<string>(item.name || "");
  const [numericalAutoSelect, setNumericalAutoSelect] = React.useState<boolean>(item.numericalAutoSelect || false)
  const [openAttachMeasurements, setOpenAttachMeasurements] = React.useState<boolean>(false)

  const debouncedRequest = useDebounce(() => {
    handleSaveCriterium && handleSaveCriterium({ ...item, name, numericalAutoSelect });
  });

  React.useEffect(() => {
    if (subcriteriaData && isFetched) {
      setSubcriteria &&
        setSubcriteria(subcriteria => {
          subcriteriaData.forEach((subcriterium: Subcriterium) => {
            subcriteria[subcriterium.id] = subcriterium;
          });
          return { ...subcriteria };
        });
    }
  }, [subcriteriaData, isFetched, setSubcriteria]);

  const handleAddSubcriterium = async () => {
    handleSaveSubcriterium &&
      (await handleSaveSubcriterium({
        name: "",
        score: 0,
        weight: 0,
        criteriumId: criteriumId,
      }));
  };

  const criteriumSubcriteria = subcriteria
    ? Object.values(subcriteria).filter(
        subcriterium => subcriterium.criteriumId === criteriumId
      )
    : [];

  return (
    <Paper elevation={3}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: "1rem",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <Box sx={{ flexShrink: 1, display: "flex", flexDirection: "column", justifyContent: 'center' }}>
            <IconButton aria-label="show-subcriteria" onClick={() => setShowSubcriterium(!showSubcriterium) }>
              { showSubcriterium ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <TextField
              variant={"outlined"}
              fullWidth
              label={"Criterium Name"}
              value={name}
              onChange={event => {
                setName(event.target.value);
                debouncedRequest();
              }}
            />
          </Box>
          <Box sx={{ flexShrink: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Button
              onClick={() =>
                handleDeleteCriterium && handleDeleteCriterium(criteriumId)
              }
            >
              <DeleteIcon />
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={numericalAutoSelect}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNumericalAutoSelect(event.target.checked)
                    debouncedRequest()
                  }}
                  name="numericalAutoSelect"
                />
              }
              label="Numerical Auto Select"
            />
          </FormGroup>
          { numericalAutoSelect && (
            <>
              <Box sx={{ flex: 1 }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => setOpenAttachMeasurements(true)}
                >
                  Attach Measurements
                </Button>
              </Box>
              <LinkMeasurementsToCriteria
                criterium={item}
                open={openAttachMeasurements}
                handleClose={() => setOpenAttachMeasurements(false)}
              />
            </>
          )}
        </Box>
        {showSubcriterium && (
          <>
            <Box sx={{ flex: 1 }}>
              {criteriumSubcriteria && handleSaveSubcriterium && (
                <DragAndDropList
                  items={criteriumSubcriteria}
                  updateItem={handleSaveSubcriterium}
                  TypeItem={SubcriteriumItem}
                  DragPreview={DragPreviewSubcriterium}
                  isTaskData={(obj: any) => !!obj?.criteriumId}
                  itemOptions={{ numericalAutoSelect }}
                />
              )}
            </Box>
            <Box
              sx={{ flex: 1, display: "flex", flexDirection: "row", gap: "1rem" }}
            >
              <Box sx={{ flex: 1 }}>
                <Button
                  fullWidth
                  variant={"outlined"}
                  onClick={handleAddSubcriterium}
                >
                  Add Sub Criterium
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
}

export function DragPreview({ item }: { item?: Criterium }) {
  return <></>;
  // return (
  //   <Box sx={{ flex: 1, display: "flex", flexDirection: "row", gap: "1rem" }}>
  //     <Box sx={{ display: "flex", alignItems: "center" }}>
  //       <ReorderIcon height={10} width={10} />
  //     </Box>
  //     <TextField
  //       value={item.name}
  //       fullWidth
  //       required={true}
  //       label="Tag Name"
  //       variant="outlined"
  //     />
  //     <TextField
  //       value={item.score}
  //       label={"Score *"}
  //       required={true}
  //       variant={"outlined"}
  //       type={"number"}
  //       fullWidth
  //     />
  //     <TextField
  //       value={item.weight}
  //       label={"Weight *"}
  //       required={true}
  //       variant={"outlined"}
  //       type={"number"}
  //       fullWidth
  //     />
  //     <TextField
  //       value={item.weightedScore}
  //       required={true}
  //       variant={"outlined"}
  //       type={"number"}
  //       fullWidth
  //     />
  //     <Button>
  //       <DeleteIcon />
  //     </Button>
  //   </Box>
  // );
}
