import * as Sentry from "@sentry/react";
import React from "react";
import { Ranch } from "../types";
import RanchApi from "../api/Ranch.api";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import toast from "react-hot-toast";
import { HttpError } from "../types/http-error";

type Option = {
  label: string;
  value?: Ranch;
  inputValue?: string;
};

const filter = createFilterOptions<Option>();

export default function RanchCreateableSelect({
  ranch,
  setRanch,
  label,
}: {
  ranch?: Ranch;
  setRanch: (arg?: Ranch) => void;
  label: string;
}) {
  const [openDialog, toggleDialogOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<Option | null>(
    ranch ? { label: ranch.name, value: ranch } : null
  );
  const [inputValue, setInputValue] = React.useState<string>("");

  const { mutateAsync, isLoading } = RanchApi.useSave({
    name: inputValue,
  });

  const handleClose = () => {
    setRanch(undefined);
    setValue({ label: "", value: undefined });
    toggleDialogOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const data = await mutateAsync();
      setRanch(data);
      setValue({ label: data.name, value: data });
      toggleDialogOpen(false);
      toast.success("Successfully saved.");
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      if (e instanceof HttpError && e.message) {
        toast.error(e.message);
      } else {
        toast.error("Failed to create Ranch");
      }
    }
  };

  const { data, isFetching } = RanchApi.useList({ query: inputValue });
  const options: Option[] = data
    ? data.data.map((ranch: Ranch) => ({ label: ranch.name, value: ranch }))
    : [];

  return (
    <>
      <Autocomplete
        autoHighlight
        selectOnFocus={true}
        clearOnBlur={true}
        handleHomeEndKeys={true}
        freeSolo={true}
        loading={isFetching}
        value={value}
        onChange={(event: any, newValue: Option | string | null) => {
          if (typeof newValue === "string") {
            setTimeout(() => {
              toggleDialogOpen(true);
              setInputValue(newValue);
              setValue({
                inputValue: newValue,
                label: newValue,
                value: undefined,
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleDialogOpen(true);
            setInputValue(newValue.label);
            setValue({
              inputValue: newValue.label,
              label: newValue.label,
              value: undefined,
            });
          } else {
            setValue(newValue);
            setRanch(newValue?.value as Ranch);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              value: undefined,
              label: params.inputValue,
              inputValue: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        getOptionLabel={option => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.label;
        }}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              {option.inputValue ? option.inputValue : option.label}
            </li>
          );
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options}
        renderInput={params => <TextField {...params} label={label} />}
      />
      <Dialog open={openDialog} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new Ranch</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Not finding the Ranch in the list? Please, add it!
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={value?.label || ""}
              onChange={event => setValue({ label: event.target.value })}
              label="Name"
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" disabled={isLoading}>
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
