import { useInfiniteQuery, useMutation, useQuery } from "react-query";

import {
  Person,
  PersonInput,
  MetaPagination,
  Param,
  CulturalPractice,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleInfiniteList,
  handleList,
  handleSave,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useInfiniteList: (query?: string) => {
    const params: Param[] = [];
    if (query) {
      params.push({
        key: "search",
        value: query,
      });
    }

    return useInfiniteQuery({
      queryKey: ["people"],
      queryFn: handleInfiniteList<Person>({ baseUrl: "people", params }),
      // initialPageParam: 0,
      keepPreviousData: false,
      getNextPageParam: (lastPage, pages) => lastPage.meta.nextPage,
      getPreviousPageParam: (firstPage, pages) => firstPage.meta.prevPage,
    });
  },
  useList: ({ query, page }: { query?: string; page?: number }) => {
    const params: Param[] = [];
    if (query) {
      params.push({
        key: "search",
        value: query,
      });
    }
    if (page) {
      params.push({
        key: "page",
        value: page,
      });
    }
    return useQuery({
      queryKey: ["people", query?.toString(), page?.toString()],
      queryFn: () => handleList<Person>({ baseUrl: "people", params }),
    });
  },
  useDetail: (id?: number | string) => {
    return useQuery({
      queryKey: ["person", id?.toString()],
      queryFn: () => handleDetail<Person>({ id, baseUrl: "people" }),
      enabled: !!id,
    });
  },
  useSave: (input: PersonInput) => {
    return useMutation<Person>({
      mutationFn: () => handleSave({ baseUrl: "people", input }),
      retry: 1,
      onSuccess: async data => {
        await queryClient.refetchQueries(["people"]);
      },
    });
  },
  useDelete: (id: number | string) => {
    return useMutation<{ id: number }>({
      mutationFn: () => handleDelete({ baseUrl: "people", id }),
      onSuccess: async ({ id }: { id: number }) => {
        queryClient.setQueryData(["people"], (oldData: any) => {
          const newPages = oldData.pages.map(
            (group: { data: Person[]; meta: MetaPagination }) => ({
              data: group.data.filter(
                person => person.id?.toString() !== id?.toString()
              ),
              meta: group.meta,
            })
          );
          return {
            pages: newPages,
            pageParams: oldData.pageParams,
          };
        });
      },
    });
  },
  useAssociatedWith: ({
    regionId,
    commodityId,
    growerIds,
    subGrowerIds,
    supplierIds,
    shipperIds,
    culturalPractice,
  }: {
    regionId?: number;
    commodityId?: number;
    growerIds?: number[];
    subGrowerIds?: number[];
    supplierIds?: number[];
    shipperIds?: number[];
    culturalPractice: CulturalPractice;
  }) => {
    const params: Param[] = [];
    if (regionId) {
      params.push({
        key: "region_id",
        value: regionId,
      });
    }
    if (commodityId) {
      params.push({
        key: "commodity_id",
        value: commodityId,
      });
    }
    if (culturalPractice) {
      params.push({
        key: "cultural_practice",
        value: culturalPractice,
      });
    }
    if (!supplierIds) {
      if (growerIds) {
        params.push({
          key: "grower_ids",
          value: growerIds.join(","),
        });
      }
      if (subGrowerIds) {
        params.push({
          key: "subgrower_ids",
          value: subGrowerIds.join(","),
        });
      }
      if (shipperIds) {
        params.push({
          key: "shipper_ids",
          value: shipperIds.join(","),
        });
      }
    } else if (supplierIds) {
      params.push({
        key: "supplier_ids",
        value: supplierIds.join(","),
      });
    }

    const queryKeyStr = params.map((param: any) => param['value'] as string).join("-")

    return useQuery({
      queryKey: [
        "people",
        "associated",
        queryKeyStr
      ],
      queryFn: () =>
        handleSimpleList<Person>({
          url: "people/associated",
          params,
        }),
    });
  },
};
export default methods;
