import React from "react";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import invariant from "tiny-invariant";
import {
  attachClosestEdge,
  type Edge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { Box } from "@mui/material";
import { createPortal } from "react-dom";
import DragAndDropIndicator from "./DragAndDropIndicator";

type TaskState =
  | {
      type: "idle";
    }
  | {
      type: "preview";
      container: HTMLElement;
    }
  | {
      type: "is-dragging";
    }
  | {
      type: "is-dragging-over";
      closestEdge: Edge | null;
    };

const idle: TaskState = { type: "idle" };

export default function DragAndDropItem<
  Type extends { id: number; name: string },
>({
  item,
  TypeItem,
  DragPreview,
  isTaskData,
  currentlyDragging,
  itemOptions
}: {
  item: Type;
  TypeItem: React.FC<{
    item: Type;
    itemOptions?: any;
  }>;
  DragPreview: React.FC<{ item?: Type }>;
  isTaskData: (obj: any) => boolean;
  currentlyDragging?: Type;
  itemOptions?: any;
}) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [state, setState] = React.useState<TaskState>(idle);

  React.useEffect(() => {
    const element = ref.current;
    invariant(element);
    return combine(
      draggable({
        element,
        getInitialData() {
          return item as Record<string, unknown>;
        },
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: "16px",
              y: "8px",
            }),
            render({ container }) {
              setState({ type: "preview", container });
            },
          });
        },
        onDragStart() {
          setState({ type: "is-dragging" });
        },
        onDrop() {
          setState(idle);
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          // not allowing dropping on yourself
          if (source.element === element) {
            return false;
          }
          // only allowing tasks to be dropped on me
          return isTaskData(source.data);
          // return true;
        },
        getData({ input }) {
          const data = item as Record<string, unknown>;
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        getIsSticky() {
          return true;
        },
        onDragEnter({ self, source, location }) {
          const closestEdge = extractClosestEdge(self.data);
          setState({ type: "is-dragging-over", closestEdge });
        },
        onDrag({ self }) {
          const closestEdge = extractClosestEdge(self.data);

          // Only need to update react state if nothing has changed.
          // Prevents re-rendering.
          setState(current => {
            if (
              current.type === "is-dragging-over" &&
              current.closestEdge === closestEdge
            ) {
              return current;
            }
            return { type: "is-dragging-over", closestEdge };
          });
        },
        onDragLeave() {
          setState(idle);
        },
        onDrop() {
          setState(idle);
        },
      })
    );
  }, [item, isTaskData]);
  return (
    <>
      <Box className="relative">
        <Box
          // Adding data-attribute as a way to query for this for our post drop flash
          data-task-id={item.id}
          ref={ref}
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          {state.type === "is-dragging-over" &&
            state.closestEdge &&
            state.closestEdge === "top" && (
              <DragAndDropIndicator edge={state.closestEdge} gap={"8px"} />
            )}
          <TypeItem item={item} itemOptions={itemOptions} />
          {state.type === "is-dragging-over" &&
            state.closestEdge &&
            state.closestEdge === "bottom" && (
              <DragAndDropIndicator edge={state.closestEdge} gap={"8px"} />
            )}
        </Box>
      </Box>
      {state.type === "preview"
        ? createPortal(<DragPreview item={item} />, state.container)
        : null}
    </>
  );
}
