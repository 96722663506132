import React from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import UserApi from "../api/User.api";
import SimpleListName from "../components/SimpleListName";
import IndexHeaderButton from "../ui/IndexHeaderButton";
import { User } from "../types";
import { Box, Grid, Typography } from "@mui/material";

export default function ProfileList() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  let headers = [
    {
      key: "lastName",
      label: "Last Name",
      getValue: (obj: User) => obj.lastName,
    },
    {
      key: "firstName",
      label: "First Name",
      getValue: (obj: User) => obj.firstName,
    },
    {
      key: "admin",
      label: "PD",
      getValue: (obj: User) => (obj.admin ? "Yes" : "No"),
    },
  ];
  if (!isSmallScreen) {
    headers = [...headers.slice(0, 2), { key: "email", label: "Email", getValue: (obj: User) => obj.email }, ...headers.slice(2)];
  }
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Grid container spacing={2}>
        <Grid item xs={9} sm={8}>
          <Typography
            variant={isSmallScreen ? "h4" : "h2"}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            Users
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sm={4}
          sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}
        >
          <IndexHeaderButton
            handleCreate={() => {
              navigate("/users/create");
            }}
          />
        </Grid>
      </Grid>
      <SimpleListName
        useList={UserApi.useInfiniteList}
        useDelete={UserApi.useDelete}
        baseUrl="users"
        headers={headers}
      />
    </Box>
  );
}
