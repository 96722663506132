import React from "react";
import { Commodity, EvaluableType } from "../../types";
import MeasurementEvaluationAverage from "./MeasurementEvaluationAverage";
import { useEvaluationContext } from "../../contexts/Evaluation.context";

export default function MeasurementEvaluations({
  commodity,
  evaluableId,
  evaluableType,
}: {
  commodity: Commodity;
  evaluableId: number;
  evaluableType: EvaluableType;
}) {
  const {
    measurements,
    measurementEvaluations,
    measurementEvaluationsIsLoading,
  } = useEvaluationContext();

  console.log('evaluations', measurementEvaluations)

  return (
    <>
      {measurements &&
        !measurementEvaluationsIsLoading &&
        measurements.map(measurement => (
          <MeasurementEvaluationAverage
            key={measurement.id}
            evaluableId={evaluableId}
            evaluableType={evaluableType}
            measurement={measurement}
            measurementEvaluations={
              measurementEvaluations
                ? measurementEvaluations.filter(
                    me => me.measurementId === measurement.id
                  )
                : []
            }
          />
        ))}
    </>
  );
}
