import { liteClient } from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch";
import AlgoliaTrialList from "../components/AlgoliaTrialList";
import AlgoliaTrialSearchBox from "../components/AlgoliaTrialSearchBox";
import React from 'react'

const searchClient = liteClient(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID || "",
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY || ""
);

export default function TrialList() {
  const [excludeYears, setExcludeYears] = React.useState(false);
  return (
    <>
      <InstantSearch
        searchClient={searchClient}
        indexName={`Trial_${process.env.REACT_APP_ENV}`}
      >
        <AlgoliaTrialSearchBox
          excludeYears={excludeYears}
          setExcludeYears={setExcludeYears}
        />
        <AlgoliaTrialList
          excludeYears={excludeYears}
          setExcludeYears={setExcludeYears}
        />
      </InstantSearch>
    </>
  );
}
