import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  FormControl,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Trial, TrialVariety, Variety } from "../../types";
import { capitalize, uniqBy } from "lodash";
import dayjs from "dayjs";
import FieldVarietyApi from "../../api/FieldVariety.api";
import TrialVarietyApi from "../../api/TrialVariety.api";

export default function ReportHeader({
  trial,
  supplierId,
  setSupplierId,
}: {
  trial: Trial;
  supplierId?: number;
  setSupplierId: (arg?: number) => void;
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { data: trialVarieties } = TrialVarietyApi.useList(trial.id);
  const { data: fieldVarieties } = FieldVarietyApi.useList(trial.id);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {trialVarieties && (
          <FormControl variant={"standard"} sx={{ width: isSmallScreen ? "100%" : "20rem" }}>
            <InputLabel id="supplier-select-label">Supplier</InputLabel>
            <Select
              labelId="supplier-select-label"
              id="supplier-select"
              value={supplierId}
              label="Supplier"
              onChange={event => {
                const value = event.target.value;
                if (value) {
                  setSupplierId(event.target.value as number);
                } else {
                  setSupplierId(undefined);
                }
              }}
            >
              <MenuItem value={undefined}>None</MenuItem>
              {uniqBy(
                trialVarieties.map(
                  (trialVariety: TrialVariety) => trialVariety.variety
                ),
                "companyId"
              ).map((variety: Variety) => (
                <MenuItem
                  key={variety?.company?.id}
                  value={variety?.company?.id}
                >
                  {variety?.company?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Typography variant={"body1"}>Evaluator:</Typography>
          <Typography variant={"body1"}>{trial?.user?.name}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Typography variant={"body1"}>Commodity:</Typography>
          <Typography variant="body1">{trial?.commodity?.name}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Typography variant={"body1"}>Shipper:</Typography>
          <Typography variant={"body1"}>{trial?.shipper?.name}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Typography variant={"body1"}>Field Varieties: </Typography>
          <Typography variant={"body1"}>
            {fieldVarieties &&
              fieldVarieties
                ?.map(fieldVariety => fieldVariety.variety.name)
                .join(", ")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Typography variant={"body1"}>Cultural Practice:</Typography>
          <Typography variant={"body1"}>
            {capitalize(trial?.culturalPractice)}
          </Typography>
        </Box>
      </Grid>
      {isSmallScreen && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Typography variant={"body1"}>Grower:</Typography>
          <Typography variant={"body1"}>{trial?.grower?.name}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Typography variant={"body1"}>SubGrower:</Typography>
          <Typography variant="body1">{trial?.subGrower?.name}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Typography variant={"body1"}>City:</Typography>
          <Typography variant="body1">{trial?.region?.name}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Typography variant={"body1"}>Ranch:</Typography>
          <Typography variant={"body1"}>{trial?.ranch?.name}</Typography>
        </Box>
      </Grid>
      {isSmallScreen && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Typography variant={"body1"}>Lot: </Typography>
          <Typography variant={"body1"}>{trial?.lot}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Typography variant={"body1"}>Wet Date: </Typography>
          <Typography variant={"body1"}>
            {trial?.wetDate && dayjs(trial?.wetDate).format("MMM D, YYYY")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Typography variant={"body1"}>Harvest Date: </Typography>
          <Typography variant={"body1"}>
            {trial?.estHarvestDate &&
              dayjs(trial?.estHarvestDate).format("MMM D, YYYY")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Typography variant={"body1"}>Lng:</Typography>
          <Typography variant="body1">{trial?.lng?.slice(0, -8)}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Typography variant={"body1"}>Lat:</Typography>
          <Typography variant="body1">{trial?.lat?.slice(0, -8)}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"body1"}>{trial?.comments}</Typography>
      </Grid>
    </Grid>
  );
}
