import React from 'react'
import * as Sentry from "@sentry/react";
import { Grid, Modal, Box, Button, TextField, Typography } from "@mui/material";
import { useCommodityContext } from "../../contexts/Commodity.context";
import toast from "react-hot-toast";
import { HttpError } from "../../types/http-error";
import MeasurementApi from '../../api/Measurement.api'
import { isNaN, isEmpty } from 'lodash'
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import dayjs from 'dayjs'
import MeasurementCard from './MeasurementCard'

export default function Measurements(){
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { measurements, commodity } = useCommodityContext();

  const [name, setName] = React.useState<string>("")
  const [numOfMeasurements, setNumOfMeasurements] = React.useState<number>(6)
  const [open, setOpen] = React.useState<boolean>(false)

  const { mutateAsync } = MeasurementApi.useSave()

  const handleCreate = async () => {
    try {
      if(!commodity) return;
      await mutateAsync({
        name,
        numOfMeasurements,
        commodityId: commodity && commodity.id
      })
      setOpen(false)
      setName("")
      setNumOfMeasurements(6)
      toast.success("Successfully created.");
    } catch(error){
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to create.");
      }
    }
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallScreen ? "100%" : "70%",
    height: isSmallScreen ? "100%" : 'auto',
    overflow:'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    justifyContent: "center",
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", py: "1rem" }}>
      <Box sx={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
        <Box sx={{flexShrink: 1}}>
          <Button variant="outlined" onClick={() => setOpen(true)}>
            Create new Measurement
          </Button>
        </Box>
      </Box>
      <Box>
        {measurements && !isEmpty(measurements) ? (
          <Grid container spacing={1}>
            {measurements.sort((a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix()).map(measurement => (
              <Grid item xs={12} sm={6} md={4} key={measurement.id}>
                <MeasurementCard measurement={measurement} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box sx={{ py: "2rem", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h6">No measurments created for this commodity yet</Typography>
          </Box>
        )}
      </Box>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={modalStyle}>
          <Box sx={{ flexShrink: 1 }}>
            <Typography variant="h6">Create Measurement</Typography>
          </Box>
          <Box sx={{ flexShrink: 1, display: "flex", flexDirection: "column", gap: "1rem" }}>
            <TextField
              label="Name"
              value={name}
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
            />
            <TextField
              fullWidth
              value={numOfMeasurements}
              onChange={event => {
                const value = event.target.value;
                const parsedValue = parseInt(value);
                if (!isNaN(parsedValue) && parsedValue >= 0) {
                  setNumOfMeasurements(parseInt(value));
                } else {
                  setNumOfMeasurements(0);
                }
              }}
              label="Number of Measurements Used"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box sx={{ flexShrink: 1, display: "flex", flexDirection: isSmallScreen ? "column" : "row", gap: "1rem"}}>
            <Box sx={{flex:1}}>
              <Button fullWidth onClick={handleCreate} variant="contained">Create</Button>
            </Box>
            <Box sx={{flex:1}}>
              <Button fullWidth onClick={() => setOpen(false)} variant="contained" color="info">
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}
