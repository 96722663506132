import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// @ts-ignore
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
// @ts-ignore
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { EvaluableType } from "../../types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import toast from "react-hot-toast";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const API_ROOT = process.env.REACT_APP_BASE_URL;

export default function ImageUpload({
  evaluableId,
  evaluableType,
  refetchImages,
}: {
  evaluableId: number;
  evaluableType: EvaluableType;
  refetchImages: () => any;
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);

  const serverUrl = () => {
    let urlStr = `${API_ROOT}/trial_varieties/${evaluableId}/image`;
    if (evaluableType === "field_variety") {
      urlStr = `${API_ROOT}/field_varieties/${evaluableId}/image`;
    }
    return urlStr;
  };

  const handleProcessFilesCompleted = async () => {
    const {data} = await refetchImages();
    toast.success(`Total number of images now is ${data?.length}`)
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallScreen ? "100%" : "70%",
    height: isSmallScreen ? "100%" : '100%',
    overflow:'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  }

  return (
    <>
      <Button
        fullWidth
        variant={"contained"}
        color={"info"}
        onClick={() => setOpen(true)}
      >
        <PhotoCameraIcon fontSize="large" color="primary" />
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={modalStyle}>
          <Box>
            <Typography variant={"h6"}>Max 3 files at a time</Typography>
          </Box>
          <FilePond
            allowMultiple={true}
            maxFiles={3}
            server={serverUrl()}
            allowRevert={false}
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            onprocessfiles={handleProcessFilesCompleted}
          />
          <Box>
            <Button
              fullWidth
              variant="contained"
              color="info"
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
