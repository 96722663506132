import * as Sentry from "@sentry/react";
import React from "react";
import {
  createPlotBedByLocation,
  createPlotBedLocation,
  getPlotBedNumbers,
  getPlotNumbersByPlotId,
  isTypeVarietyComplete
} from "../utils";
import {
  NurseryVariety,
  PlotBed,
  PlotBedGrandParentType,
  PlotType,
  TrialVariety,
  Variety,
} from "../types";
import PlotGridLayout from "./PlotGridLayout";
import VarietySelect from "./VarietySelect";
import { Typography, Box, Chip, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import LabelIcon from "@mui/icons-material/Label";
import { isEmpty } from "lodash";
import PlotBedApi from "../api/PlotBed.api";
import CheckIcon from "@mui/icons-material/CheckCircle";
import dayjs from 'dayjs'
import toast from "react-hot-toast";

export default function PlotGrid<Type extends TrialVariety | NurseryVariety>({
  createTypeVariety,
  deleteTypeVariety,
  parentId,
  typeVarieties,
  grandParentTypeVariety,
  plotType,
  plotRows,
  plotCols,
  setSelectedTrialVariety,
}: {
  createTypeVariety: (typeVariety: any) => Promise<Type>;
  deleteTypeVariety: (typeVariety: Type) => Promise<any>;
  parentId?: number;
  typeVarieties: Type[];
  grandParentTypeVariety: PlotBedGrandParentType;
  plotType: PlotType;
  plotRows: number;
  plotCols: number;
  setSelectedTrialVariety?: (trialVariety?: TrialVariety) => void;
}) {
  const { data: plotBeds } = PlotBedApi.useList({
    parentId: parentId,
    parentType: grandParentTypeVariety,
  });

  const plotBedByLocation: { [key: string]: PlotBed } =
    createPlotBedByLocation(plotBeds);

  const [selectedTypeVariety, setSelectedTypeVariety] = React.useState<
    Type | undefined
  >(undefined);
  const [selectedPlotBeds, setSelectedPlotBeds] = React.useState<{
    [key: string]: { row: number; col: number };
  }>({});
  const [selectedVariety, setSelectedVariety] = React.useState<
    Variety | undefined
  >(undefined);

  const handleTypeVarietySelect = (typeVariety: Type) => () => {
    setSelectedTypeVariety(typeVariety);
    // @ts-ignore
    setSelectedTrialVariety && setSelectedTrialVariety(typeVariety);
    const newSelectedPlotBeds: { [key: string]: PlotBed } = {};
    Object.values(plotBedByLocation).forEach(plotBed => {
      if (plotBed.plotsId === typeVariety.id) {
        newSelectedPlotBeds[plotBed.key] = plotBed;
      }
    });
    setSelectedPlotBeds(newSelectedPlotBeds);
  };

  const handleDeleteTypeVariety = (typeVariety: Type) => async () => {
    try {
      await deleteTypeVariety(typeVariety);
      if (selectedTypeVariety?.id === typeVariety.id) {
        setSelectedTypeVariety(undefined);
        setSelectedTrialVariety && setSelectedTrialVariety(undefined);
      }
      toast.success("Successfully deleted.");
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      toast.error("Failed to delete.");
    }
  };

  const selectPlotBed = (rowIndex: number, colIndex: number) => {
    const locationId = createPlotBedLocation(rowIndex, colIndex);
    setSelectedPlotBeds(selectedPlotBeds => {
      if (plotBedByLocation[locationId]) {
        const plotsId = plotBedByLocation[locationId].plotsId;
        const selectedPlotBeds: {
          [key: string]: { row: number; col: number };
        } = {};
        Object.values(plotBedByLocation)
          .filter(plotBed => plotBed.plotsId === plotsId)
          .forEach(plotBed => {
            selectedPlotBeds[plotBed.key] = {
              row: plotBed.row,
              col: plotBed.col,
            };
          });
        return selectedPlotBeds;
      } else if (selectedPlotBeds[locationId]) {
        const newSelectedPlotBed = { ...selectedPlotBeds };
        delete newSelectedPlotBed[locationId];
        return newSelectedPlotBed;
      } else {
        if (checkPlotBedKeyIsNotPartOfPlotBetGroup(locationId)) {
          return {
            ...selectedPlotBeds,
            [locationId]: { row: rowIndex, col: colIndex },
          };
        } else {
          return { [locationId]: { row: rowIndex, col: colIndex } };
        }
      }
    });
    if (plotBedByLocation[locationId]) {
      const newSelectedTypeVariety = typeVarieties.find(
        typeVariety => plotBedByLocation[locationId].plotsId === typeVariety.id
      );
      if (newSelectedTypeVariety) {
        setSelectedTypeVariety(newSelectedTypeVariety);
        // @ts-ignore
        setSelectedTrialVariety &&
          // @ts-ignore
          setSelectedTrialVariety(newSelectedTypeVariety);
      }
    }
  };

  const checkPlotBedKeyIsNotPartOfPlotBetGroup = (
    plotBedKey: string
  ): boolean => {
    const selectedPlotBedKeys = Object.keys(selectedPlotBeds);
    const alreadyPartOfPlotBedGroup = Object.keys(plotBedByLocation).findIndex(
      plotBedKey => selectedPlotBedKeys.includes(plotBedKey)
    );
    return alreadyPartOfPlotBedGroup === -1;
  };

  const handleCreatePlotBed = async () => {
    try {
      if (parentId && selectedVariety && !isEmpty(selectedPlotBeds)) {
        await createTypeVariety({
          [`${grandParentTypeVariety}Id`]: parentId,
          varietyId: selectedVariety.id,
          plotBeds: Object.values(selectedPlotBeds),
        });
        toast.success("Successfully created.");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      toast.error("Failed to create plot bed.");
    }
  };

  const setupPlotGrid = () => {
    const plotNumbers = getPlotBedNumbers({
      plotBeds: plotBedByLocation,
      plotType,
      rows: plotRows,
      cols: plotCols,
    });
    const rows = [];
    for (let rowIndex = plotRows - 1; rowIndex >= 0; rowIndex--) {
      const cols = [];
      for (let colIndex = 0; colIndex < plotCols; colIndex++) {
        const locationId = createPlotBedLocation(rowIndex, colIndex);
        const plotNumber = plotNumbers[locationId];

        cols.push({
          key: locationId,
          rowIndex: rowIndex,
          colIndex: colIndex,
          plotNumber: plotNumber,
        });
      }
      rows.push(cols);
    }
    return rows;
  };
  const rows = setupPlotGrid();

  const partOfPlotBedGroup = Object.keys(selectedPlotBeds).find(plotBedKey =>
    checkPlotBedKeyIsNotPartOfPlotBetGroup(plotBedKey)
  );

  const plotNumbersByPlotId = getPlotNumbersByPlotId({
    plotBeds: plotBedByLocation,
    plotType,
    rows: parseInt(plotRows.toString()),
    cols: parseInt(plotCols.toString()),
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {selectedPlotBeds && partOfPlotBedGroup && (
        <Grid container>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexGrow: 1,
                width: "100%",
                gap: "1rem",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <VarietySelect
                  label="Select a variety"
                  variety={selectedVariety}
                  setVariety={setSelectedVariety}
                />
              </Box>
              <IconButton
                disabled={!selectedVariety}
                onClick={handleCreatePlotBed}
                color="primary"
                size="large"
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid sx={{ flex: 1 }}>
        <PlotGridLayout
          rows={rows}
          selectedPlotBeds={selectedPlotBeds}
          selectPlotBed={selectPlotBed}
        />
      </Grid>
      <Grid item xs={12}>
        {typeVarieties
          .sort((a, b) => plotNumbersByPlotId[a.id] - plotNumbersByPlotId[b.id])
          .map(typeVariety => (
            <Box sx={{ my: 1 }} key={typeVariety.id}>
              <Chip
                onClick={handleTypeVarietySelect(typeVariety)}
                variant={
                  selectedTypeVariety?.id === typeVariety.id
                    ? "filled"
                    : "outlined"
                }
                label={
                  <Box sx={{display: "flex", flexDirection: "row", gap: "0.3rem", alignItems: "center", justifyContent: "center"}}>
                    <Typography variant="body1">{plotNumbersByPlotId[typeVariety.id]}</Typography>
                    <Typography variant="body1">{typeVariety.variety.name}</Typography>
                    <Typography variant="caption">{dayjs(typeVariety.updatedAt).format("MMM D, YYYY")}</Typography>
                  </Box>
                }
                onDelete={handleDeleteTypeVariety(typeVariety)}
                avatar={isTypeVarietyComplete(typeVariety) ? <CheckIcon /> : <LabelIcon />}
              />
            </Box>
          ))}
      </Grid>
    </Box>
  );
}
