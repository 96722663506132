import { useMutation, useQuery } from "react-query";
import {
  EvaluableType,
  MultiEvaluation,
  MultiEvaluationInput,
  Param,
} from "../types";
import { handleSave, handleSimpleList } from "./handler";
import queryClient from "./queryClient";
import { unionBy } from "lodash";

const methods = {
  useList: ({
    multiEvaluableType,
    multiEvaluableId,
  }: {
    multiEvaluableType: EvaluableType;
    multiEvaluableId: number;
  }) => {
    const params: Param[] = [];
    params.push({
      key: "multi_evaluable_id",
      value: multiEvaluableId,
    });
    params.push({
      key: "multi_evaluable_type",
      value: multiEvaluableType,
    });
    return useQuery<MultiEvaluation[]>({
      queryKey: ["multi_evaluations", multiEvaluableType, multiEvaluableId?.toString()],
      queryFn: () =>
        handleSimpleList<MultiEvaluation>({ url: "multi_evaluations", params }),
    });
  },
  useSave: () => {
    return useMutation({
      mutationFn: ({
        multiEvaluationInput,
        multiEvaluableId,
        multiEvaluableType,
      }: {
        multiEvaluationInput: MultiEvaluationInput[];
        multiEvaluableType: EvaluableType;
        multiEvaluableId: number;
      }) =>
        handleSave<MultiEvaluation[]>({
          baseUrl: "multi_evaluations",
          input: {
            multi_evaluations: multiEvaluationInput,
            multi_evaluable_type: multiEvaluableType,
            multi_evaluable_id: multiEvaluableId,
          },
        }),
      onSuccess: async (data: MultiEvaluation[]) => {
        const multiEvalType = data[0].multiEvalType;
        const multiEvalId = data[0].multiEvalId;
        queryClient.setQueryData(
          ["multi_evaluations", multiEvalType, multiEvalId.toString()],
          (oldData: MultiEvaluation[] | undefined) => {
            if (oldData) {
              return unionBy(data, oldData, "id");
            } else {
              return data;
            }
          }
        );
      },
    });
  },
};
export default methods;
