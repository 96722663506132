import {
  dropTargetForElements,
  monitorForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { Box } from "@mui/material";
import React from "react";
import { flushSync } from "react-dom";
import { reorderWithEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge";
import { triggerPostMoveFlash } from "@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash";
import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import DragAndDropItem from "./DragAndDropItem";
import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import invariant from "tiny-invariant";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";

export default function DragAndDropList<
  Type extends { id: number; name: string; location: number },
>({
  items,
  updateItem,
  TypeItem,
  DragPreview,
  isTaskData,
  itemOptions
}: {
  items: Type[];
  updateItem: (arg: Type) => Promise<Type | undefined>;
  TypeItem: React.FC<{
    item: Type;
  }>;
  DragPreview: React.FC<{ item?: Type }>;
  isTaskData: (obj: any) => boolean;
  itemOptions?: any
}) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [currentlyDragging, setCurrentlyDragging] = React.useState<
    Type | undefined
  >(undefined);
  React.useEffect(() => {
    const element = ref.current;
    invariant(element, "Element ref not set");

    return combine(
      dropTargetForElements({
        element,
      }),
      // A scrollable element does not need to be a drop target,
      // but in this case it is.
      // We can add auto scrolling to an element along side our other
      // Pragmatic drag and drop bindings
      autoScrollForElements({
        element,
      })
    );
  });

  React.useEffect(() => {
    return monitorForElements({
      // canMonitor({ source }) {
      //   return isTaskData(source.data);
      // },
      canMonitor({ source }) {
        const sourceData = source.data;
        if (!isTaskData(sourceData)) {
          setCurrentlyDragging(undefined);
          return false;
        }
        const item = items.find((item: Type) => item.id === sourceData.id);
        setCurrentlyDragging(item);
        return true;
      },
      // onDragStart({}) {
      //
      // },
      onDrop({ location, source }) {
        const target = location.current.dropTargets[0];
        if (!target) {
          return;
        }

        const sourceData = source.data;
        const targetData = target.data;

        if (!sourceData || !targetData) {
          return;
        }
        if (!isTaskData(sourceData) || !isTaskData(targetData)) {
          return;
        }

        const indexOfSource = items.findIndex(
          (item: Type) => item.id === sourceData.id
        );
        const indexOfTarget = items.findIndex(
          (item: Type) => item.id === targetData.id
        );

        if (indexOfTarget < 0 || indexOfSource < 0) {
          return;
        }

        const closestEdgeOfTarget = extractClosestEdge(targetData);

        // Using `flushSync` so we can query the DOM straight after this line
        flushSync(() => {
          const swappedItems = reorderWithEdge({
            list: items,
            startIndex: indexOfSource,
            indexOfTarget,
            closestEdgeOfTarget,
            axis: "vertical",
          });
          return swappedItems.map((item, index) => {
            item.location = index;
            updateItem({ ...item, location: index });
            return item;
          });
        });

        // Being simple and just querying for the task after the drop.
        // We could use react context to register the element in a lookup,
        // and then we could retrieve that element after the drop and use
        // `triggerPostMoveFlash`. But this gets the job done.
        const element = document.querySelector(
          `[data-task-id="${sourceData.taskId}"]`
        );
        if (element instanceof HTMLElement) {
          triggerPostMoveFlash(element);
        }
      },
    });
  }, [items, isTaskData, updateItem]);

  return (
    <Box
      ref={ref}
      style={{ overflowY: "scroll" }}
      sx={{
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        py: "0.5rem",
      }}
    >
      {items
        .sort((a, b) => a.location - b.location)
        .map((item: Type) => (
          <DragAndDropItem
            key={item.id}
            item={item}
            currentlyDragging={currentlyDragging}
            DragPreview={DragPreview}
            TypeItem={TypeItem}
            isTaskData={isTaskData}
            itemOptions={itemOptions}
          />
        ))}
    </Box>
  );
}
