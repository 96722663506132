import { liteClient } from "algoliasearch/lite";
import { InstantSearch, useInstantSearch } from "react-instantsearch";
import AlgoliaTrialList from "../components/AlgoliaTrialList";
import AlgoliaTrialSearchBox from "../components/AlgoliaTrialSearchBox";
import { createInsightsMiddleware } from "instantsearch.js/es/middlewares";
import React from "react";

const searchClient = liteClient(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID || "",
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY || ""
);

export default function PersonalizedTrials() {
  const [excludeYears, setExcludeYears] = React.useState(false);
  return (
    <>
      <InstantSearch
        searchClient={searchClient}
        indexName={`Trial_${process.env.REACT_APP_ENV}`}
        insights
      >
        <InsightsMiddleware />
        <AlgoliaTrialSearchBox
          personalized={true}
          excludeYears={excludeYears}
          setExcludeYears={setExcludeYears}
        />
        <AlgoliaTrialList
          excludeYears={excludeYears}
          setExcludeYears={setExcludeYears}
        />
      </InstantSearch>
    </>
  );
}

function InsightsMiddleware() {
  const { addMiddlewares } = useInstantSearch();

  React.useLayoutEffect(() => {
    const middleware = createInsightsMiddleware({
      // @ts-ignore
      insightsClient: window.aa,
    });

    return addMiddlewares(middleware);
  }, [addMiddlewares]);

  return null;
}
