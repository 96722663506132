import React from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, Grid, Typography } from "@mui/material"

import AreaApi from "../api/Area.api";
import SimpleListName from "../components/SimpleListName";
import IndexHeaderButton from "../ui/IndexHeaderButton";
import { Area } from "../types";

export default function AreaList() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem"}}>
      <Grid container spacing={2}>
        <Grid item xs={9} sm={8}>
          <Typography
            variant={isSmallScreen ? "h4" : "h2"}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            Areas
          </Typography>
        </Grid>
        <Grid item xs={3} sm={4} sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end"}}>
          <IndexHeaderButton
            handleCreate={() => {
              navigate("/areas/create");
            }}
          />
        </Grid>
      </Grid>
      <SimpleListName
        useList={AreaApi.useInfiniteList}
        useDelete={AreaApi.useDelete}
        baseUrl="areas"
        headers={[
          { key: "name", label: "Name", getValue: (obj: Area) => obj.name },
        ]}
      />
    </Box>
  );
}
