import { useInfiniteQuery, useMutation, useQuery } from "react-query";

import {
  Commodity,
  CommodityInput,
  ListResponseData,
  MetaPagination,
  Param,
  Tag,
} from "../types";
import {
  handleBasicPost,
  handleDelete,
  handleDetail,
  handleInfiniteList,
  handleList,
  handleSave,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useList: ({ query, page }: { query?: string; page?: number }) => {
    const params: Param[] = [];
    if (query) {
      params.push({
        key: "search",
        value: query,
      });
    }
    if (page) {
      params.push({
        key: "page",
        value: page,
      });
    }
    return useQuery<ListResponseData<Commodity>>({
      queryKey: ["commodities", query?.toString(), page?.toString()],
      queryFn: () => handleList<Commodity>({ baseUrl: "commodities", params }),
    });
  },
  useInfiniteList: (query?: string) => {
    const params: Param[] = [];
    if (query) {
      params.push({
        key: "search",
        value: query,
      });
    }
    return useInfiniteQuery<{ data: Commodity[]; meta: MetaPagination }>({
      queryKey: ["commodities"],
      queryFn: handleInfiniteList<Commodity>({
        baseUrl: "commodities",
        params,
      }),
      // initialPageParam: 0,
      keepPreviousData: false,
      getNextPageParam: (lastPage, pages) => lastPage.meta.nextPage,
      getPreviousPageParam: (firstPage, pages) => firstPage.meta.prevPage,
    });
  },
  useByVariety: (varietyId: number | string) => {
    return useQuery<Commodity[]>({
      queryKey: ["commodities", "byVariety", varietyId?.toString()],
      queryFn: () =>
        handleSimpleList<Commodity>({
          url: `varieties/${varietyId}/commodities`,
        }),
    });
  },
  useDetail: (id?: number | string) => {
    return useQuery({
      queryKey: ["commodity", id?.toString()],
      queryFn: () => handleDetail<Commodity>({ id, baseUrl: "commodities" }),
      enabled: !!id,
    });
  },
  useSave: (input: CommodityInput) => {
    return useMutation<Commodity>({
      mutationFn: () => handleSave({ baseUrl: "commodities", input }),
      retry: 1,
      onSuccess: async data => {
        await queryClient.refetchQueries(["commodities"]);
      },
    });
  },
  // useLabels: ({ commodityId }: { commodityId: number | string }) => {
  //   return useQuery<Label[]>({
  //     queryKey: ["commodity_labels", commodityId.toString()],
  //     queryFn: () => handleSimpleList({ url: `commodities/${commodityId}/labels` }),
  //   })
  // },
  useTags: ({
    commodityId,
    labelId,
  }: {
    commodityId: number | string;
    labelId: number | string;
  }) => {
    return useQuery<Tag[]>({
      queryKey: [
        "commodity_label_tags",
        commodityId.toString(),
        labelId.toString(),
      ],
      queryFn: () =>
        handleSimpleList({
          url: `commodities/${commodityId}/tags`,
          params: [{ key: "label_id", value: labelId }],
        }),
    });
  },
  useAddTag: ({
    commodityId,
    labelId,
  }: {
    commodityId: number;
    labelId: number;
  }) => {
    return useMutation({
      mutationFn: ({ tagId }: { tagId: number | string }) =>
        handleBasicPost({
          url: `commodities/${commodityId}/add_tag`,
          input: { tag_id: tagId },
        }),
      retry: 1,
      onSuccess: async (data: Tag[]) => {
        queryClient.setQueryData(
          ["commodity_label_tags", commodityId.toString(), labelId.toString()],
          () => {
            return data;
          }
        );
      },
    });
  },
  useRemoveTag: ({
    commodityId,
    labelId,
  }: {
    commodityId: number;
    labelId: number;
  }) => {
    return useMutation({
      mutationFn: ({ tagId }: { tagId: number | string }) =>
        handleBasicPost({
          url: `commodities/${commodityId}/remove_tag`,
          input: { tag_id: tagId },
        }),
      retry: 1,
      onSuccess: async (data: Tag[]) => {
        queryClient.setQueryData(
          ["commodity_label_tags", commodityId.toString(), labelId.toString()],
          () => {
            return data;
          }
        );
      },
    });
  },
  useDelete: (id?: number | string) => {
    return useMutation<{ id: number }>({
      mutationFn: () => handleDelete({ baseUrl: "commodities", id }),
      onSuccess: async ({ id }: { id: number }) => {
        queryClient.setQueryData(["commodities"], (oldData: any) => {
          const newPages = oldData.pages.map(
            (group: { data: Commodity[]; meta: MetaPagination }) => ({
              data: group.data.filter(
                obj => obj.id?.toString() !== id?.toString()
              ),
              meta: group.meta,
            })
          );
          return {
            pages: newPages,
            pageParams: oldData.pageParams,
          };
        });
      },
    });
  },
};

export default methods;
