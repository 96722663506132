import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { EvaluableType, MeasurementEvaluation, Measurement } from "../../types";
import MeasurementEvaluationComponent from './MeasurementEvaluation'
import { useEvaluationContext } from '../../contexts/Evaluation.context'

export default function MeasurementEvaluationAverage({
  evaluableId,
  evaluableType,
  measurement,
  measurementEvaluations
}: {
  evaluableId: number;
  evaluableType: EvaluableType;
  measurement: Measurement;
  measurementEvaluations: MeasurementEvaluation[];
}){
  const { getAverageScoreByMeasurementId } = useEvaluationContext()

  const averageScore = getAverageScoreByMeasurementId ? getAverageScoreByMeasurementId({ measurementId: measurement.id }) : 0;

  return (
    <React.Fragment key={measurement.id}>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", px: "0.25rem" }}>
          <Typography variant={"subtitle1"}>{measurement.name}</Typography>
          <Typography variant={"subtitle1"}>Score: {Math.round((averageScore + Number.EPSILON) * 100) / 100}</Typography>
        </Box>
      </Grid>
      {Array.from({length: measurement.numOfMeasurements}, (_, i) => i).map(measurementIndex => (
        <MeasurementEvaluationComponent
          key={`${measurement.id}-${measurementIndex}`}
          evaluableId={evaluableId}
          evaluableType={evaluableType}
          measurement={measurement}
          measurementEvaluation={
            measurementEvaluations &&
            measurementEvaluations.find(me => me.measurementIndex === measurementIndex)
          }
          measurementIndex={measurementIndex}
        />
      ))}
    </React.Fragment>
  )
}