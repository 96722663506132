import React from "react";
import { Box, Button,TextField } from "@mui/material";
import CriteriumForm, { DragPreview } from "./CriteriumForm";
import { useCommodityContext } from "../../contexts/Commodity.context";
import DragAndDropList from "../DragAndDropList";

export default function Criteria(){
  const {
    maxScore,
    criteria,
    handleCreateCriterium,
    handleSaveCriterium,
  } = useCommodityContext();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", py: "1rem" }}>
      <Box sx={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Box sx={{flexShrink: 1}}>
          <TextField variant="outlined" label="Max Score" disabled value={maxScore} />
        </Box>
        <Box sx={{flexShrink: 1, display: "flex", flexDirection: "column", justifyContent: "center"}}>
          <Button
            fullWidth
            variant={"outlined"}
            color={"primary"}
            onClick={handleCreateCriterium}
          >
            Create Criterium
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {criteria && handleSaveCriterium && (
          <DragAndDropList
            items={Object.values(criteria)}
            updateItem={handleSaveCriterium}
            TypeItem={CriteriumForm}
            DragPreview={DragPreview}
            isTaskData={(obj: any) => !!obj?.commodityId}
          />
        )}
      </Box>
    </Box>
  )
}