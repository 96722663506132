import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Switch,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2"
import React from "react";
import { useReportCompareContext } from "../../contexts/ReportCompare.context";
import { Area, Commodity, Company, Region } from "../../types";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function Filters({
  showFilters,
  setShowFilters,
}: {
  showFilters: boolean;
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {
    fieldVarieties,
    trialVarieties,
    displayGrower,
    setDisplayGrower,
    displayRanch,
    setDisplayRanch,
    displayShipper,
    setDisplayShipper,
    displaySupplier,
    setDisplaySupplier,
    filterOrganic,
    setFilterOrganic,
    filterGrowersLike,
    setFilterGrowersLike,
    filterConventional,
    setFilterConventional,
    excludeYears,
    setExcludeYears,
    searchByWetDate,
    setSearchByWetDate,
    commodityOptionsSelected,
    setCommodityOptionsSelected,
    supplierOptionsSelected,
    setSupplierOptionsSelected,
    filteredAreas,
    setFilteredAreas,
    filteredShippers,
    setFilteredShippers,
    filteredGrowers,
    setFilteredGrowers,
    filteredRegions,
    setFilteredRegions,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = useReportCompareContext();
  const areas: { [key: number]: Area } = {};
  const commodities: { [key: string]: Commodity } = {};
  const growers: { [key: number]: Company } = {};
  const regions: { [key: number]: Region } = {};
  const shippers: { [key: number]: Company } = {};
  const suppliers: { [key: string]: Company } = {};

  const typeVarieties =
    (fieldVarieties &&
      trialVarieties &&
      fieldVarieties.concat(trialVarieties)) ||
    [];
  typeVarieties.forEach(typeVariety => {
    if (typeVariety.trial.grower) {
      growers[typeVariety.trial.grower.id] = typeVariety.trial.grower;
    }
    if (typeVariety.trial.shipper) {
      shippers[typeVariety.trial.shipper.id] = typeVariety.trial.shipper;
    }
    if (typeVariety.variety.company) {
      suppliers[typeVariety?.variety?.company?.id?.toString()] =
        typeVariety.variety.company;
    }
    if (typeVariety.trial.area) {
      areas[typeVariety.trial.area.id] = typeVariety.trial.area;
    }
    if (typeVariety.trial.region) {
      regions[typeVariety.trial.region.id] = typeVariety.trial.region;
    }
    if (typeVariety.variety.commodities) {
      typeVariety.variety.commodities.forEach(commodity => {
        commodities[commodity.id?.toString()] = commodity;
      });
    }
  });

  const growerOptions = Object.values(growers).map(grower => ({
    value: grower,
    label: grower.name,
  }));
  const shipperOptions = Object.values(shippers).map(shipper => ({
    value: shipper,
    label: shipper.name,
  }));
  const areaOptions = Object.values(areas).map(area => ({
    value: area,
    label: area.name,
  }));
  const regionOptions = Object.values(regions).map(region => ({
    value: region,
    label: region.name,
  }));

  return (
    <Drawer
      open={showFilters}
      anchor={"top"}
      onClose={() => setShowFilters(false)}
    >
      <FormControl sx={{ p: "1rem" }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <FormLabel component={"legend"}>Growers</FormLabel>
            <Autocomplete
              sx={{ mt: "0.5rem" }}
              multiple
              id="growers-filter"
              value={
                (filteredGrowers &&
                  filteredGrowers.map(grower => ({
                    label: grower.name,
                    value: grower,
                  }))) ||
                []
              }
              onChange={(event, newValue) => {
                setFilteredGrowers &&
                  setFilteredGrowers(newValue.map(option => option.value));
              }}
              options={growerOptions}
              getOptionLabel={option => option.label}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select Growers"
                  placeholder="Growers"
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <FormLabel component={"legend"}>Shippers</FormLabel>
            <Autocomplete
              sx={{ mt: "0.5rem" }}
              multiple
              id="shippers-filter"
              value={
                (filteredShippers &&
                  filteredShippers.map(shipper => ({
                    label: shipper.name,
                    value: shipper,
                  }))) ||
                []
              }
              onChange={(event, newValue) => {
                setFilteredShippers &&
                  setFilteredShippers(newValue.map(option => option.value));
              }}
              options={shipperOptions}
              getOptionLabel={option => option.label}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select Shippers"
                  placeholder="Shippers"
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <FormLabel component={"legend"}>Areas</FormLabel>
            <Autocomplete
              sx={{ mt: "0.5rem" }}
              multiple
              id="areas-filter"
              value={
                (filteredAreas &&
                  filteredAreas.map(area => ({
                    label: area.name,
                    value: area,
                  }))) ||
                []
              }
              onChange={(event, newValue) => {
                setFilteredAreas &&
                  setFilteredAreas(newValue.map(option => option.value));
              }}
              options={areaOptions}
              getOptionLabel={option => option.label}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select Areas"
                  placeholder="Areas"
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <FormLabel component={"legend"}>Regions</FormLabel>
            <Autocomplete
              sx={{ mt: "0.5rem" }}
              multiple
              value={
                (filteredRegions &&
                  filteredRegions.map(region => ({
                    label: region.name,
                    value: region,
                  }))) ||
                []
              }
              onChange={(event, newValue) => {
                setFilteredRegions &&
                  setFilteredRegions(newValue.map(option => option.value));
              }}
              id="regions-filter"
              options={regionOptions}
              getOptionLabel={option => option.label}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select Regions"
                  placeholder="Regions"
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            {/* Commodities   */}
            <FormLabel component={"legend"}>Commodities</FormLabel>
            <FormGroup>
              {commodityOptionsSelected &&
                Object.keys(commodityOptionsSelected).map(
                  (commodityId: string) => (
                    <FormControlLabel
                      key={commodityId}
                      control={
                        <Checkbox
                          checked={commodityOptionsSelected[commodityId]}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setCommodityOptionsSelected &&
                              setCommodityOptionsSelected(coptions => {
                                coptions[commodityId] = event.target.checked;
                                return { ...coptions };
                              });
                          }}
                        />
                      }
                      label={commodities[commodityId]?.name}
                    />
                  )
                )}
            </FormGroup>
          </Grid>
          <Grid size={{ xs: 6, sm: 4, md: 3 }}>
            {/*  Suppliers */}
            <FormLabel component={"legend"}>Suppliers</FormLabel>
            <FormGroup>
              {supplierOptionsSelected &&
                Object.keys(supplierOptionsSelected).map(
                  (supplierId: string) => (
                    <FormControlLabel
                      key={supplierId}
                      control={
                        <Checkbox
                          checked={supplierOptionsSelected[supplierId]}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setSupplierOptionsSelected &&
                              setSupplierOptionsSelected(options => {
                                options[supplierId] = event.target.checked;
                                return { ...options };
                              });
                          }}
                        />
                      }
                      label={suppliers[supplierId]?.name}
                    />
                  )
                )}
            </FormGroup>
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            {/* Cultural Practice */}
            <FormGroup>
              <FormLabel component={"legend"}>Cultural Practice</FormLabel>
              <FormControlLabel
                label="Organic"
                control={
                  <Switch
                    checked={filterOrganic}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFilterOrganic &&
                        setFilterOrganic(event.target.checked);
                    }}
                  />
                }
              />
              <FormControlLabel
                label="Conventional"
                control={
                  <Switch
                    checked={filterConventional}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFilterConventional &&
                        setFilterConventional(event.target.checked);
                    }}
                  />
                }
              />
            </FormGroup>
            {/* Grower Feeling  */}
            <FormGroup>
              <FormLabel component={"legend"}>Grower's Feeling</FormLabel>
              <FormControlLabel
                label="Like"
                control={
                  <Switch
                    checked={filterGrowersLike}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFilterGrowersLike &&
                        setFilterGrowersLike(event.target.checked);
                    }}
                  />
                }
              />
            </FormGroup>
          </Grid>
          <Grid
            size={{ xs: 12, sm: 6, md: 4 }}
            sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            {/* Date Range, startDate, endDate, excludeYears, wetDateSearch Swtich    */}
            <FormLabel component={"legend"}>Date Range</FormLabel>
            <DatePicker
              label={"Start Date"}
              sx={{ width: "100%" }}
              value={startDate || null}
              onChange={newValue => setStartDate && setStartDate(newValue)}
            />
            <DatePicker
              label={"End Date"}
              sx={{ width: "100%" }}
              value={endDate || null}
              onChange={newValue => setEndDate && setEndDate(newValue)}
            />
            <FormGroup>
              <FormControlLabel
                label="Exclude Years"
                control={
                  <Switch
                    checked={excludeYears}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setExcludeYears && setExcludeYears(event.target.checked);
                    }}
                  />
                }
              />
              <FormControlLabel
                label="Date Search by Wet Date"
                control={
                  <Switch
                    checked={searchByWetDate}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchByWetDate &&
                        setSearchByWetDate(event.target.checked);
                    }}
                  />
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      </FormControl>
      <Divider />
      <FormControl sx={{ p: "1rem" }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={displayGrower}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setDisplayGrower &&
                        setDisplayGrower(event.target.checked);
                    }}
                  />
                }
                label="Grower"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={displayRanch}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setDisplayRanch && setDisplayRanch(event.target.checked);
                    }}
                  />
                }
                label="Ranch"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={displayShipper}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setDisplayShipper &&
                        setDisplayShipper(event.target.checked);
                    }}
                  />
                }
                label="Shipper"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={displaySupplier}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setDisplaySupplier &&
                        setDisplaySupplier(event.target.checked);
                    }}
                  />
                }
                label="Supplier"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </FormControl>
      <Divider />
      <FormControl sx={{ p: "1rem" }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <Button
              onClick={() => setShowFilters(false)}
              size={"large"}
              variant={"contained"}
              color={"primary"}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </Drawer>
  );
}
