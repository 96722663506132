import {  useQuery } from "react-query";
import { CriteriaMeasurement } from '../types'
import { handleSimpleList } from "./handler";

const methods = {
  useByCommodityList: ({ commodityId }: { commodityId: number | string }) => {
    return useQuery<CriteriaMeasurement[]>({
      enabled: !!commodityId,
      queryKey: ["criteria_measurements", commodityId.toString()],
      queryFn: () =>
        handleSimpleList<CriteriaMeasurement>({
          url: `commodities/${commodityId}/criteria_measurements`
        }),
    });
  },
}

export default methods;