import * as Sentry from "@sentry/react";
import React from "react";
import PersonalViewApi from "../api/PersonalView.api";
import { Commodity, Company, Region } from "../types";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import DeleteConfirmation from "./DeleteConfirmation";
import CommoditySelect from "./CommoditySelect";
import CompanySelect from "./CompanySelect";
import RegionSelect from "./RegionSelect";
import toast from "react-hot-toast";

export default function UserConnections({ userId }: { userId: number }) {
  const { data } = PersonalViewApi.useList(userId);

  const [commodity, setCommodity] = React.useState<Commodity | undefined>(
    undefined
  );
  const [region, setRegion] = React.useState<Region | undefined>(undefined);
  const [company, setCompany] = React.useState<Company | undefined>(undefined);

  const { mutateAsync } = PersonalViewApi.useSave({
    userId: userId,
    commodityId: commodity?.id,
    regionId: region?.id,
    companyId: company?.id,
  });
  const handleCreateConnection = async () => {
    try {
      if (!commodity && !region && !company) {
        toast.error("Must select at least one Commodity, Company or Region");
        return;
      }
      await mutateAsync();
      toast.success("Successfully saved.");
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error("Failed to create connection");
    }
  };

  return (
    <Grid container spacing={2}>
      {data &&
        data.map(personalView => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={personalView.id}>
            <Card
              sx={{
                height: "100%",
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <CardContent
                sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
              >
                <Typography variant="h5" color="textSecondary">
                  Commodity:{" "}
                  {personalView?.commodity
                    ? personalView?.commodity?.name
                    : "All"}
                </Typography>
                <Typography variant="h5" color="textSecondary">
                  Company:{" "}
                  {personalView?.company ? personalView?.company?.name : "All"}
                </Typography>
                <Typography variant="h5" color="textSecondary">
                  Region:{" "}
                  {personalView?.region ? personalView?.region?.name : "All"}
                </Typography>
              </CardContent>
              <CardActions>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={12} sm={8} md={5} lg={3}>
                    <DeleteConfirmation
                      useDelete={PersonalViewApi.useDelete}
                      id={personalView.id}
                    />
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        ))}
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Card>
          <CardContent
            sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <CommoditySelect
              commodity={commodity}
              setCommodity={commodity => setCommodity(commodity)}
              label="Commodity"
            />
            <CompanySelect
              company={company}
              setCompany={company => setCompany(company)}
              label="Company"
            />
            <RegionSelect
              region={region}
              setRegion={region => setRegion(region)}
              label="Region"
            />
            <Button onClick={handleCreateConnection}>Add Connection</Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
