import { Box, Button, Divider, Grid, TextField, Typography, IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { useCommodityContext } from "../contexts/Commodity.context";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Measurements from './commodities/Measurements';
import Labels from "./commodities/Labels";
import Criteria from "./commodities/Criteria"
import { useDebounce } from "../utils";

export default function CommodityForm() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    commodity,
    setCommodity,
    criteria,
    labels,
    measurements,
    handleSaveCommodity,
    handleDeleteCommodity,
  } = useCommodityContext();

  const [showLabels, setShowLabels] = React.useState<boolean>(false);
  const [showMeasurements, setShowMeasurements] = React.useState<boolean>(false)
  const [showCriteria, setShowCriteria] = React.useState<boolean>(false)

  const debouncedRequest = useDebounce(() => {
    handleSaveCommodity && handleSaveCommodity();
  });

  const handleDelete = async () => {
    const res = handleDeleteCommodity && (await handleDeleteCommodity());
    if (res) {
      navigate(`/commodities`);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box sx={{display: "flex", flexDirection: "row", gap: "1rem", justifyContent: "space-between"}}>
          <Typography variant={isSmallScreen ? "h4" : "h2"} gutterBottom>
            {commodity?.id ? "Edit Commodity" : "Create Commodity"}
          </Typography>
          {commodity?.id && (
            <Box sx={{flexShrink: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Button
                fullWidth
                variant={"outlined"}
                color={"error"}
                onClick={handleDelete}
              >
                Delete Commodity
              </Button>
            </Box>
          )}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          value={commodity?.name || ""}
          label="Name"
          onChange={event => {
            setCommodity && setCommodity(c => c && { ...c, name: event.target.value });
            debouncedRequest();
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "1rem"}}>
          <Box sx={{ flexShrink: 1 }}>
            <Typography variant="h5">Labels</Typography>
          </Box>
          <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="body1">{labels ? labels.length : 0} labels attached</Typography>
          </Box>
          <Box sx={{ flexShrink: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            <IconButton aria-label="show-labels" onClick={() => setShowLabels(l => !l)}>
              { showLabels ? <RemoveIcon /> : <AddIcon /> }
            </IconButton>
          </Box>
        </Box>
        { showLabels && (
          <Labels />
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "1rem"}}>
          <Box sx={{ flexShrink: 1 }}>
            <Typography variant="h5">Criteria</Typography>
          </Box>
          <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="body1">{criteria ? Object.keys(criteria).length : 0} criteria attached</Typography>
          </Box>
          <Box sx={{ flexShrink: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            <IconButton aria-label="show-criteria" onClick={() => setShowCriteria(!showCriteria) }>
              { showCriteria ? <RemoveIcon /> : <AddIcon /> }
            </IconButton>
          </Box>
        </Box>
        { showCriteria && (
          <Criteria />
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "1rem"}}>
          <Box sx={{ flexShrink: 1 }}>
            <Typography variant="h5">Measurements</Typography>
          </Box>
          <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="body1">{measurements ? measurements.length : 0} measurements attached</Typography>
          </Box>
          <Box sx={{ flexShrink: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            <IconButton aria-label="show-measurements" onClick={() => setShowMeasurements(m => !m)}>
              { showMeasurements ? <RemoveIcon /> : <AddIcon /> }
            </IconButton>
          </Box>
        </Box>
        {showMeasurements && (
          <Measurements />
        )}
      </Grid>
    </Grid>
  );
}
