import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  InputAdornment,
  TextField,
} from "@mui/material";
import {
  useSearchBox,
  UseSearchBoxProps,
} from "react-instantsearch";

function AlgoliaSearchBox({ query, isSearchStalled, refine }: any) {
  return (
    <Box>
      <TextField
        id={"input-with-icon-variety-combine"}
        label="Search"
        value={query}
        onChange={e => refine(e.target.value)}
        autoFocus={true}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}

export default function CustomSearchBox(props: UseSearchBoxProps) {
  const searchBoxApi = useSearchBox(props);
  return (
    <>
      {/*<Configure filters={[]}/>*/}
      <AlgoliaSearchBox {...searchBoxApi} />
    </>
  );
}
