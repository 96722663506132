import { useReportCompareContext } from "../../contexts/ReportCompare.context";
import TrialEvaluationColumn from "../reports/TrialEvaluationColumn";
import Grid from '@mui/material/Grid2';
import { ReportType } from "../../types";
import dayjs from "dayjs";

export default function EvaluationColumns({
  reportType,
}: {
  reportType: ReportType;
}) {
  const { excludeYears, searchByWetDate, filteredCombinedVarieties } =
    useReportCompareContext();

  const sortedColumns = (filteredCombinedVarieties || []).sort((a, b) => {
    const convertDate = excludeYears
      ? (d: string) => dayjs(d).dayOfYear()
      : (d: string) => dayjs(d);
    const orderByWetDate = searchByWetDate;
    let dateA: any = null;
    let dateB: any = null;
    if (orderByWetDate) {
      const wetDateA = a.typeVariety?.trial?.wetDate;
      dateA = convertDate(wetDateA);
      const wetDateB = b.typeVariety?.trial?.wetDate;
      dateB = convertDate(wetDateB);
    } else {
      const maturityDateA = a.typeVariety?.maturityDate;
      const maturityDateB = b.typeVariety?.maturityDate;
      dateA = convertDate(maturityDateA);
      dateB = convertDate(maturityDateB);
    }
    if (dateA > dateB) {
      return 1;
    }
    if (dateA < dateB) {
      return -1;
    }
    return 0;
  });

  return (
    <Grid
      container
      spacing={2}
    >
      {sortedColumns.map(column => (
        <Grid
          key={column.typeVariety.id}
          size={{ xs: 12, sm: 6, md: 4 }}
        >
          <TrialEvaluationColumn
            reportType={reportType}
            typeVariety={column.typeVariety}
            evaluableType={column.evaluableType}
          />
        </Grid>
      ))}
    </Grid>
  );
}
