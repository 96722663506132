import { Box, Button, TextField } from "@mui/material";
import ReorderIcon from "@mui/icons-material/Reorder";
import { Subcriterium } from "../../types";
import DeleteIcon from "@mui/icons-material/Delete";
import { round } from "lodash";
import React from "react";
import { useDebounce } from "../../utils";
import { useCommodityContext } from "../../contexts/Commodity.context";
import toast from "react-hot-toast";

export default function SubcriteriumItem({
  item,
  itemOptions
}:
{
  item: Subcriterium;
  itemOptions?: any;
}) {
  const { handleSaveSubcriterium, handleDeleteSubcriterium } =
    useCommodityContext();

  const [name, setName] = React.useState<string>(item.name || "");
  const debouncedNameRequest = useDebounce(() => {
    handleSaveSubcriterium && handleSaveSubcriterium({ ...item, name: name });
  });
  const [score, setScore] = React.useState<number>(item.score);
  const debouncedScoreRequest = useDebounce(() => {
    handleSaveSubcriterium && handleSaveSubcriterium({ ...item, score: score });
  });
  const [weight, setWeight] = React.useState<number>(item.weight);
  const debouncedWeightRequest = useDebounce(() => {
    handleSaveSubcriterium &&
      handleSaveSubcriterium({ ...item, weight: weight });
  });

  const [numericalMax, setNumericalMax] = React.useState<number | undefined>(item.numericalMax)
  const debouncedMaxRequest = useDebounce(() => {
    handleSaveSubcriterium && handleSaveSubcriterium({ ...item, numericalMax: numericalMax });
  });
  const [numericalMin, setNumericalMin] = React.useState<number | undefined>(item.numericalMin)
  const debouncedMinRequest = useDebounce(() => {
    handleSaveSubcriterium && handleSaveSubcriterium({ ...item, numericalMin: numericalMin });
  });

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "row", gap: "1rem" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ReorderIcon height={10} width={10} />
      </Box>
      <TextField
        value={name}
        fullWidth
        required={true}
        label="Tag Name"
        variant="outlined"
        onChange={event => {
          setName(event.target.value);
          debouncedNameRequest();
        }}
      />
      {itemOptions && itemOptions.numericalAutoSelect && (
        <>
          <TextField
            value={numericalMax}
            label={"Max"}
            required={false}
            variant={"outlined"}
            type={"number"}
            fullWidth
            onChange={event => {
              const value = event.target.value
              if(value && numericalMin && parseFloat(value) <= numericalMin){
                toast.error("Must be more than Min");
                return;
              }
              if(value && parseFloat(value) >= 0){
                setNumericalMax(round(parseFloat(value), 2));
              } else {
                setNumericalMax(undefined);
              }
              debouncedMaxRequest()
            }}
          />
          <TextField
            value={numericalMin}
            label={"Min"}
            required={false}
            variant={"outlined"}
            type={"number"}
            fullWidth
            onChange={event => {
              const value = event.target.value
              if(value && numericalMax && parseFloat(value) >= numericalMax){
                toast.error("Must be less than Max");
                return
              }
              if(value && parseFloat(value) >= 0){
                setNumericalMin(round(parseFloat(value), 2));
              } else {
                setNumericalMin(undefined);
              }
              debouncedMinRequest()
            }}
          />
        </>
      )}
      <TextField
        value={score}
        label={"Score"}
        required={true}
        variant={"outlined"}
        type={"number"}
        fullWidth
        onChange={event => {
          const newScore = parseInt(event.target.value);
          if (newScore > 10 || newScore < 0) {
            toast.error("Score must be between 0 and 10");
          } else {
            setScore(newScore);
            debouncedScoreRequest();
          }
        }}
      />
      <TextField
        value={weight}
        label={"Weight"}
        required={true}
        variant={"outlined"}
        type={"number"}
        fullWidth
        onChange={event => {
          const newWeight = parseFloat(event.target.value);
          if (newWeight > 1 || newWeight < 0) {
            toast.error("Weight must be between 0 and 1");
          } else {
            setWeight(newWeight);
            debouncedWeightRequest();
          }
        }}
      />
      <TextField
        value={round(score * weight, 2)}
        required={true}
        variant={"outlined"}
        type={"number"}
        fullWidth
      />
      <Button
        onClick={() =>
          handleDeleteSubcriterium && handleDeleteSubcriterium(item)
        }
      >
        <DeleteIcon />
      </Button>
    </Box>
  );
}

export function DragPreview({ item }: { item?: Subcriterium }) {
  if (!item) {
    return <></>;
  }
  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "row", gap: "1rem" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ReorderIcon height={10} width={10} />
      </Box>
      <TextField
        value={item.name}
        fullWidth
        required={true}
        label="Tag Name"
        variant="outlined"
      />
      <TextField
        value={item.score}
        label={"Score *"}
        required={true}
        variant={"outlined"}
        type={"number"}
        fullWidth
      />
      <TextField
        value={item.weight}
        label={"Weight *"}
        required={true}
        variant={"outlined"}
        type={"number"}
        fullWidth
      />
      <TextField
        value={item.weightedScore}
        required={true}
        variant={"outlined"}
        type={"number"}
        fullWidth
      />
      <Button>
        <DeleteIcon />
      </Button>
    </Box>
  );
}
