import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import { Outlet } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";

import { useSession } from "../contexts/Session.context";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: prop => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Root() {
  const theme = useTheme();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const { session, setSession } = useSession();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    setSession && setSession(undefined);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ marginRight: "1rem" }}>
            <Avatar
              alt=""
              src={
                "https://holaday-assets.s3-us-west-1.amazonaws.com/logoBorder.jpg"
              }
            />
          </Box>
          <Link to={"/"} onClick={handleDrawerClose}>
            <Typography variant="h6" noWrap component="div">
              Holaday Seed Company
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItemButton  selected={location.pathname === "/trials"}>
            <Link to="/trials" onClick={handleDrawerClose}>
              <ListItemText primary="Trials" />
            </Link>
          </ListItemButton>
        </List>
        {session && session?.admin && (
          <>
            <Divider />
            <List>
              <ListItemButton  selected={location.pathname === "/commodities"}>
                <Link to="/commodities" onClick={handleDrawerClose}>
                  <ListItemText primary="Commodities" />
                </Link>
              </ListItemButton>
            </List>
            <List>
              <ListItemButton  selected={location.pathname === "/diseases"}>
                <Link to="/diseases" onClick={handleDrawerClose}>
                  <ListItemText primary="Diseases" />
                </Link>
              </ListItemButton>
            </List>
          </>
        )}
        <Divider />
        {session && session?.admin && (
          <>
            <List>
              <ListItemButton selected={location.pathname === "/areas"}>
                <Link to="/areas" onClick={handleDrawerClose}>
                  <ListItemText primary="Areas" />
                </Link>
              </ListItemButton>
              <ListItemButton selected={location.pathname === "/regions"}>
                <Link to="/regions" onClick={handleDrawerClose}>
                  <ListItemText primary="Regions" />
                </Link>
              </ListItemButton>
              <ListItemButton selected={location.pathname === "/ranches"}>
                <Link to="/ranches" onClick={handleDrawerClose}>
                  <ListItemText primary="Ranches" />
                </Link>
              </ListItemButton>
            </List>
            <Divider />
          </>
        )}
        <List>
          <ListItemButton selected={location.pathname === "/varieties"}>
            <Link to="/varieties" onClick={handleDrawerClose}>
              <ListItemText primary="Varieties" />
            </Link>
          </ListItemButton>
        </List>
        <Divider />
        {session && session?.admin && (
          <List>
            <ListItemButton selected={location.pathname === "/nurseries"}>
              <Link to="/nurseries" onClick={handleDrawerClose}>
                <ListItemText primary="Nurseries" />
              </Link>
            </ListItemButton>
          </List>
        )}
        <List>
          <ListItemButton selected={location.pathname === "/statistics"}>
            <Link to="/statistics" onClick={handleDrawerClose}>
              <ListItemText primary="Days to Harvest" />
            </Link>
          </ListItemButton>
        </List>
        {session && session?.admin && (
          <List>
            <ListItemButton selected={location.pathname === "/reports"}>
              <Link to="/reports" onClick={handleDrawerClose}>
                <ListItemText primary="Reports" />
              </Link>
            </ListItemButton>
          </List>
        )}
        <List>
          <ListItemButton selected={location.pathname === "/people"}>
            <Link to="/people" onClick={handleDrawerClose}>
              <ListItemText primary="People" />
            </Link>
          </ListItemButton>
        </List>
        {session && session?.admin && (
          <>
            <List>
              <ListItemButton selected={location.pathname === "/companies"}>
                <Link to="/companies" onClick={handleDrawerClose}>
                  <ListItemText primary="Companies" />
                </Link>
              </ListItemButton>
            </List>
            <List>
              <ListItemButton selected={location.pathname === "/users"}>
                <Link to={"/users"} onClick={handleDrawerClose}>
                  <ListItemText primary="Users" />
                </Link>
              </ListItemButton>
            </List>
          </>
        )}
        <Divider />
        <List>
          <ListItemButton
            selected={location.pathname === `/users/${session?.userId}`}
          >
            <Link to={`/users/${session?.userId}`} onClick={handleDrawerClose}>
              <ListItemText primary="My Profile" />
            </Link>
          </ListItemButton>
        </List>
        <List>
          <ListItemButton onClick={handleLogout}>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
}
