import { Button, Grid, TextField, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Tag } from "../types";
import React from "react";
import DragAndDropList from "./DragAndDropList";
import TagItem, { DragPreview } from "./TagItem";
import { useLabelContext } from "../contexts/Label.context";
import toast from "react-hot-toast";

export default function LabelForm() {
  const navigate = useNavigate();
  const {
    label,
    setLabel,
    tags,
    isLoadingSaveLabel,
    handleSaveLabel,
    handleCreateTag,
    handleSaveTag,
  } = useLabelContext();

  const onSave = async () => {
    const res = handleSaveLabel && (await handleSaveLabel());
    if (res) {
      navigate(`/diseases/${res.id}`);
    }
    toast.success("Saved Disease succesfully.");
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          {label?.id ? "Edit Disease" : "Create Disease"}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label={"Name"}
          value={label?.name || ""}
          onChange={event =>
            setLabel && setLabel(l => l && { ...l, name: event.target.value })
          }
        />
      </Grid>
      <Grid item xs={12}>
        {tags && handleSaveTag && (
          <DragAndDropList
            items={Object.values(tags) as Tag[]}
            updateItem={handleSaveTag}
            TypeItem={TagItem}
            DragPreview={DragPreview}
            isTaskData={(obj: any) => !!obj?.labelId}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <Button
          component={Link}
          to="/diseases"
          variant="contained"
          color="info"
          fullWidth
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <Button
          disabled={isLoadingSaveLabel}
          fullWidth
          variant="contained"
          color="primary"
          onClick={onSave}
        >
          Save
        </Button>
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={handleCreateTag}
        >
          Add Tag
        </Button>
      </Grid>
    </Grid>
  );
}
