import * as Sentry from "@sentry/react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import RanchApi from "../api/Ranch.api";
import { RanchInput } from "../types";
import { HttpError } from "../types/http-error";
import toast from "react-hot-toast";

export default function RanchForm({ ranch }: { ranch?: RanchInput }) {
  const navigate = useNavigate();
  const [name, setName] = React.useState<string>(ranch?.name || "");
  const { mutateAsync } = RanchApi.useSave({
    ...ranch,
    name,
  });

  const onSave = async () => {
    try {
      const res = await mutateAsync();
      navigate(`/ranches/${res.id}`);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to create Person");
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          {ranch?.id ? "Edit Ranch" : "Create Ranch"}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label={"Name"}
          value={name}
          onChange={event => setName(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          component={Link}
          to="/ranches"
          variant="contained"
          color="info"
          fullWidth
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth variant="contained" color="primary" onClick={onSave}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
}
