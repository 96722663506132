import {
  Commodity,
  Criterium,
  Subcriterium,
  Label,
  Measurement,
  SubcriteriumInput,
} from "../types";
import React from "react";
import * as Sentry from "@sentry/react";
import CommodityApi from "../api/Commodity.api";
import CriteriumApi from "../api/Criterium.api";
import LabelApi from "../api/Label.api";
import MeasurementApi from '../api/Measurement.api'
import { HttpError } from "../types/http-error";
import toast from "react-hot-toast";
import SubcriteriumApi from "../api/Subcriterium.api";

type Props = {
  id?: string;
  children: JSX.Element;
};

interface ICommodityContext {
  maxScore: string;
  commodity?: Commodity;
  setCommodity: React.Dispatch<React.SetStateAction<Commodity | undefined>>;
  criteria: { [key: number]: Criterium };
  setCriteria: React.Dispatch<
    React.SetStateAction<{ [key: number]: Criterium }>
  >;
  measurements: Measurement[];
  subcriteria: { [key: number]: Subcriterium };
  setSubcriteria: React.Dispatch<
    React.SetStateAction<{ [key: number]: Subcriterium }>
  >;
  labels: Label[];
  handleSaveCommodity: () => Promise<Commodity | undefined>;
  handleDeleteCommodity: () => Promise<boolean>;
  handleCreateCriterium: () => Promise<Criterium | undefined>;
  handleSaveCriterium: (input: Criterium) => Promise<Criterium | undefined>;
  handleDeleteCriterium: (criteriumId: number) => Promise<boolean>;
  handleSaveSubcriterium: (
    subcriteriumInput: SubcriteriumInput
  ) => Promise<Subcriterium | undefined>;
  handleDeleteSubcriterium: (subcriterium: Subcriterium) => Promise<boolean>;
}

const CommodityContext = React.createContext<Partial<ICommodityContext>>({});

export function CommodityProvider({ id, children }: Props) {
  const [commodity, setCommodity] = React.useState<Commodity | undefined>();
  const [criteria, setCriteria] = React.useState<{ [key: number]: Criterium }>(
    {}
  );
  const [subcriteria, setSubcriteria] = React.useState<{
    [key: number]: Subcriterium;
  }>({});

  const { data: commodityData, isFetched: commodityIsFetched } =
    CommodityApi.useDetail(id);
  const { data: criteriaData, isFetched: criteriaIsFetched } =
    CriteriumApi.useList({ commodityId: id, numericalAutoSelect: false });
  const { data: labels } = LabelApi.useListByCommodity(id);
  const { data: measurements } = MeasurementApi.useList(id);

  // COMMODITY
  const { mutateAsync: saveCommodity } = CommodityApi.useSave({
    ...commodity,
  });
  const { mutateAsync: deleteCommodity } = CommodityApi.useDelete(id);

  // CRITERIA
  const { mutateAsync: createCriterium } = CriteriumApi.useCreate(id);
  const { mutateAsync: saveCriterium } = CriteriumApi.useSave();
  const { mutateAsync: deleteCriterium } = CriteriumApi.useDelete({
    commodityId: id?.toString(),
  });

  // SUBCRITERIA
  const { mutateAsync: saveSubcriterium } = SubcriteriumApi.useSave();
  const { mutateAsync: deleteSubcriterium } = SubcriteriumApi.useDelete();

  React.useEffect(() => {
    if (commodityData && commodityIsFetched) {
      setCommodity(commodityData);
    }
  }, [commodityData, commodityIsFetched]);

  React.useEffect(() => {
    if (criteriaData && criteriaIsFetched) {
      setCriteria(criteria => {
        criteriaData.forEach((criterium: Criterium) => {
          criteria[criterium.id] = criterium;
        });
        return { ...criteria };
      });
    }
  }, [criteriaData, criteriaIsFetched]);

  const handleSaveCommodity = async (): Promise<Commodity | undefined> => {
    try {
      await saveCommodity();
      // TODO save all criteria
      // TODO save all subcriteria
      // TODO save all labels and tags
      toast.success("Successfully saved.");
      return
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to save Commodity");
      }
      return undefined;
    }
  };

  const handleDeleteCommodity = async () => {
    try {
      await deleteCommodity();
      toast.success("Successfully deleted.");
      return true;
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to delete Commodity");
      }
      return false;
    }
  };

  const handleCreateCriterium = async (): Promise<Criterium | undefined> => {
    try {
      const newCriterium = await createCriterium({});
      if (newCriterium) {
        setCriteria(criteria => ({
          ...criteria,
          [newCriterium.id]: newCriterium,
        }));
      }
      toast.success("Successfully saved.");
      return newCriterium;
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to create Criterium");
      }
      return undefined;
    }
  };

  const handleSaveCriterium = async (
    criteriumInput: Criterium
  ): Promise<Criterium | undefined> => {
    try {
      const criterium = await saveCriterium(criteriumInput);
      if (criterium) {
        setCriteria(criteria => ({ ...criteria, [criterium.id]: criterium }));
      }
      toast.success("Successfully saved.");
      return criterium;
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to save Criterium");
      }
      return undefined;
    }
  };

  const handleDeleteCriterium = async (
    criteriumId: number
  ): Promise<boolean> => {
    try {
      await deleteCriterium(criteriumId);
      setCriteria(criteria => {
        delete criteria[criteriumId];
        return { ...criteria };
      });
      toast.success("Successfully deleted.");
      return true;
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to delete Criterium");
      }
      return false;
    }
  };

  const handleSaveSubcriterium = async (
    subcriteriumInput: SubcriteriumInput
  ): Promise<Subcriterium | undefined> => {
    try {
      const newSubcriterium = await saveSubcriterium(subcriteriumInput);
      if (newSubcriterium) {
        setSubcriteria(subcriteria => ({
          ...subcriteria,
          [newSubcriterium.id]: newSubcriterium,
        }));
      }
      toast.success("Successfully saved.");
      return newSubcriterium;
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to create Subcriterium");
      }
      return undefined;
    }
  };

  const handleDeleteSubcriterium = async (
    subcriterium: Subcriterium
  ): Promise<boolean> => {
    try {
      await deleteSubcriterium(subcriterium);
      setSubcriteria(subcriteria => {
        delete subcriteria[subcriterium.id];
        return { ...subcriteria };
      });
      toast.success("Successfully deleted.");
      return true;
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to delete Subcriterium");
      }
      return false;
    }
  };

  const calcuateMaxScore = () => {
    const criteriumMaxScore: { [key: number]: number } = {};
    Object.values(subcriteria).forEach((subcriterium: Subcriterium) => {
      const criteriumId = subcriterium.criteriumId;
      const criterium = criteria[criteriumId];
      if (
        criterium &&
        commodity &&
        criterium.commodityId === commodity.id &&
        (!criteriumMaxScore[criteriumId] ||
          subcriterium.score * subcriterium.weight >
            criteriumMaxScore[criteriumId])
      ) {
        criteriumMaxScore[criteriumId] =
          subcriterium.score * subcriterium.weight;
      }
    });
    return Object.values(criteriumMaxScore)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      .toFixed(2);
  };

  const maxScore = calcuateMaxScore();

  return (
    <CommodityContext.Provider
      value={{
        maxScore,
        commodity,
        setCommodity,
        criteria,
        setCriteria,
        subcriteria,
        setSubcriteria,
        labels,
        measurements,
        handleSaveCommodity,
        handleDeleteCommodity,
        handleCreateCriterium,
        handleSaveCriterium,
        handleDeleteCriterium,
        handleSaveSubcriterium,
        handleDeleteSubcriterium,
      }}
    >
      {children}
    </CommodityContext.Provider>
  );
}

export function useCommodityContext() {
  return React.useContext(CommodityContext);
}
