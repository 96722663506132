import React from "react";
import { useParams } from "react-router-dom";
import LabelForm from "../components/LabelForm";
import { LabelProvider } from "../contexts/Label.context";

export default function DiseaseShow() {
  const { id } = useParams<{ id: string }>();

  return (
    <LabelProvider id={id}>
      <LabelForm />
    </LabelProvider>
  );
}
