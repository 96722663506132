import { Commodity, EvaluableType } from "../../types";
import { Grid } from "@mui/material";
import Evaluation from "./Evaluation";
import { useEvaluationContext } from '../../contexts/Evaluation.context'

export default function Evaluations({
  commodity,
  evaluableId,
  evaluableType,
}: {
  commodity: Commodity;
  evaluableId: number;
  evaluableType: EvaluableType;
}) {
  const { criteria, evaluations } = useEvaluationContext()

  return (
    <>
      {criteria &&
        evaluations &&
        // evaluationsIsLoading &&
        criteria.map(criterium => (
          <Grid item xs={12} sm={6} md={4} key={criterium.id}>
            <Evaluation
              evaluableId={evaluableId}
              evaluableType={evaluableType}
              criterium={criterium}
              key={criterium.id}
              evaluation={evaluations.find(
                evaluation =>
                  evaluation.commodityId === criterium.commodityId &&
                  evaluation.criteriumId === criterium.id
              )}
            />
          </Grid>
        ))}
    </>
  );
}
