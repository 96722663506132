import React from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import CompanyApi from "../api/Company.api";
import SimpleListName from "../components/SimpleListName";
import IndexHeaderButton from "../ui/IndexHeaderButton";
import { Company } from "../types";
import { capitalize } from "lodash";
import { Box, Grid, Typography } from "@mui/material";

export default function CompanyList() {
    const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem"}}>
      <Grid container spacing={2}>
        <Grid item xs={9} sm={8}>
          <Typography
            variant={isSmallScreen ? "h4" : "h2"}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            Companies
          </Typography>
        </Grid>
        <Grid item xs={3} sm={4} sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end"}}>
          <IndexHeaderButton
            handleCreate={() => {
              navigate("/companies/create");
            }}
          />
        </Grid>
      </Grid>
      <SimpleListName
        useList={CompanyApi.useInfiniteList}
        useDelete={CompanyApi.useDelete}
        baseUrl="companies"
        headers={[
          { key: "name", label: "Name", getValue: (obj: Company) => obj.name },
          {
            key: "categories",
            label: "Categories",
            getValue: (obj: Company) =>
              obj?.companyCategories
                ? obj?.companyCategories
                    .map(category => capitalize(category?.name || ""))
                    .join(", ")
                : "",
          },
          {
            key: "regions",
            label: "Regions",
            getValue: (obj: Company) =>
              obj?.regions
                ? obj?.regions.map(region => region?.name).join(", ")
                : "",
          },
        ]}
      />
    </Box>
  );
}
