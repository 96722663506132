import * as Sentry from "@sentry/react";
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import RegionApi from "../api/Region.api";
import { RegionInput } from "../types";
import { HttpError } from "../types/http-error";
import toast from "react-hot-toast";

export default function RegionForm({ region }: { region?: RegionInput }) {
  const navigate = useNavigate();
  const [name, setName] = React.useState<string>(region?.name || "");
  const [mexican, setMexican] = React.useState<boolean>(
    region?.mexican || false
  );

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMexican(event.target.checked);
  };

  const { mutateAsync } = RegionApi.useSave({
    ...region,
    name,
    mexican,
  });

  const onSave = async () => {
    try {
      const res = await mutateAsync();
      navigate(`/regions/${res.id}`);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to create Person");
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          {region?.id ? "Edit Region" : "Create Region"}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label={"Name"}
          value={name}
          onChange={event => setName(event.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          label={"Mexico"}
          control={
            <Switch
              checked={mexican}
              onChange={handleSwitchChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          component={Link}
          to="/regions"
          variant="contained"
          color="info"
          fullWidth
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          fullWidth={true}
          variant="contained"
          color="primary"
          onClick={onSave}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
}
