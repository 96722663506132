import {
  Button,
  Checkbox,
  Drawer,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { Person, Trial } from "../../types";
import PersonApi from "../../api/Person.api";
import { remove } from "lodash";
import React from "react";
import toast from "react-hot-toast";
import TrialApi from "../../api/Trial.api";

export default function EmailDrawer({
  showDrawer,
  handleClose,
  trial,
  supplierId,
  sendEmail,
  autoEmailKey,
}: {
  showDrawer: boolean;
  handleClose: () => void;
  trial: Trial;
  supplierId?: number;
  sendEmail: (peopleIds: number[]) => Promise<void>;
  autoEmailKey: "auto_send_plot_report" | "auto_send_evaluation_report";
}) {
  const [checked, setChecked] = React.useState<number[]>([]);

  const { mutateAsync: updateTrial } = TrialApi.useSave({
    ...trial,
    [autoEmailKey]: true,
  });

  const handleToggle = (personId: number) => (event: any) => {
    setChecked(prevChecked => {
      if (prevChecked.indexOf(personId) === -1) {
        prevChecked.push(personId);
        return [...prevChecked];
      }
      return remove(prevChecked, (n: number) => {
        return n !== personId;
      });
    });
  };

  const { data: people } = PersonApi.useAssociatedWith({
    regionId: trial?.region?.id,
    commodityId: trial?.commodity?.id,
    growerIds: !supplierId && trial.grower?.id ? [trial.grower.id] : undefined,
    subGrowerIds:
      !supplierId && trial.subGrower?.id ? [trial.subGrower.id] : undefined,
    shipperIds:
      !supplierId && trial.shipper?.id ? [trial.shipper.id] : undefined,
    supplierIds: supplierId ? [supplierId] : undefined,
    culturalPractice: trial.culturalPractice,
  });

  const handleSendEmail = async () => {
    try {
      await sendEmail(checked);
      toast.success("Successfully saved.");
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error("Failed to send email");
    }
  };

  const sendOnDateUpdateTrial = async () => {
    try {
      await updateTrial();
      toast.success("Successfully saved.");
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error("Failed to update trial to auto send emails.");
    }
  };

  return (
    <Drawer anchor={"bottom"} open={showDrawer} onClose={handleClose}>
      <FormControl component={"fieldset"}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Table>
              <TableBody>
                {people &&
                  people
                    .sort((a: Person, b: Person) =>
                      a.lastName.toUpperCase() < b.lastName.toUpperCase()
                        ? -1
                        : 1
                    )
                    .map((person: Person) => (
                      <TableRow key={person.id}>
                        <TableCell align={"left"}>
                          <Checkbox
                            checked={checked.indexOf(person.id) === -1}
                            tabIndex={-1}
                            disableRipple
                            onClick={handleToggle(person.id)}
                          />
                        </TableCell>
                        <TableCell>
                          {person.firstName} {person.lastName}
                        </TableCell>
                        <TableCell>{person.email}</TableCell>
                        <TableCell>
                          {person.companies
                            .map(company => company.name)
                            .join(", ")}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </FormControl>
      <FormControl component="fieldset" sx={{ p: "2rem" }}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={handleSendEmail}
                >
                  Send
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={sendOnDateUpdateTrial}
                >
                  Auto
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    </Drawer>
  );
}
