import camelcaseKeys from "camelcase-keys";
import * as Sentry from "@sentry/react";
import { useMutation } from "react-query";

import { Session } from "../types";
import client from "./client";

async function postLogin(
  email: string | undefined,
  password: string | undefined
): Promise<Session> {
  try {
    if (typeof email === "undefined" || typeof password === "undefined") {
      Promise.reject(new Error("Invalid credentials"));
    }

    const data: Session = await client
      .post("login", {
        json: { email, password },
      })
      .json();
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
}

async function postResetPassword({
  token,
  password,
  passwordConfirmation,
}: {
  token: string;
  password: string;
  passwordConfirmation: string;
}): Promise<Session> {
  try {
    if (
      typeof token === "undefined" ||
      typeof password === "undefined" ||
      typeof passwordConfirmation === "undefined"
    ) {
      Promise.reject(new Error("Invalid password & password confirmation"));
    }

    const data: Session = await client
      .post("users/reset_password", {
        json: { token, password, passwordConfirmation },
      })
      .json();
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
}

async function postForgotPassword({ email }: { email: string }) {
  try {
    if (typeof email === "undefined") {
      Promise.reject(new Error("Invalid email"));
    }

    const data: Session = await client
      .post("users/forgot_password", {
        json: { email },
      })
      .json();
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
}

const methods = {
  useLogin: (email: string | undefined, password: string | undefined) =>
    useMutation<Session>({
      mutationFn: () => postLogin(email, password),
    }),
  usePasswordReset: ({
    token,
    password,
    passwordConfirmation,
    handleSuccess,
  }: {
    token: string;
    password: string;
    passwordConfirmation: string;
    handleSuccess: (arg: Session) => void;
  }) =>
    useMutation<Session>({
      mutationFn: () =>
        postResetPassword({ token, password, passwordConfirmation }),
      onSuccess: (result, variables, context) => {
        handleSuccess(result);
      },
    }),
  useForgotPassword: ({ email }: { email: string }) =>
    useMutation({
      mutationFn: () => postForgotPassword({ email }),
    }),
};
export default methods;
