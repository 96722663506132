import { useParams } from "react-router-dom";
import TrialForm from "../components/trials/TrialForm";
import { TrialProvider } from '../contexts/Trial.context'
import TrialApi from '../api/Trial.api'
import TrialVarietyApi from "../api/TrialVariety.api";
import {Box, CircularProgress} from '@mui/material';

export default function TrialShow() {
  const { id } = useParams<{ id: string }>();

  const { data: trialData, isFetched: trialIsFetched } = TrialApi.useDetail(id)
  const { data: trialVarietiesData, isFetched: trialVarietiesIsFetched } = TrialVarietyApi.useList(id);

  if(!(trialData && trialIsFetched && trialVarietiesData && trialVarietiesIsFetched)){
    return  (
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
        <CircularProgress size="3rem" />
      </Box>
    )
  }

  return (
    <TrialProvider id={id} trial={trialData} trialVarieties={trialVarietiesData} >
      <TrialForm trial={trialData} trialVarieties={trialVarietiesData} />
    </TrialProvider>
  );
}
