import { useMutation, useQuery } from "react-query";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleSave,
  handleSimpleList,
} from "./handler";
import { FieldVariety, FieldVarietyInput, ListResponseData } from "../types";
import queryClient from "./queryClient";
import { isEmpty, unionBy } from "lodash";

const methods = {
  useList: (trialId?: number | string) => {
    return useQuery<FieldVariety[]>({
      queryKey: ["field_varieties", trialId?.toString()],
      queryFn: () =>
        handleSimpleList({ url: `trials/${trialId}/field_varieties` }),
      enabled: !!trialId,
    });
  },
  useListByVarietyIds: ({
    varietyIds,
    page,
  }: {
    varietyIds: string[];
    page: number;
  }) => {
    return useQuery<ListResponseData<FieldVariety>>({
      queryKey: [
        "field_varieties_by_variety_ids",
        varietyIds.join(","),
        page.toString(),
      ],
      queryFn: () =>
        handleList({
          baseUrl: `field_varieties/by_variety_ids`,
          params: [
            { key: "ids", value: varietyIds.join(",") },
            { key: "page", value: page },
          ],
        }),
      enabled: !isEmpty(varietyIds),
    });
  },
  useDetail: ({
    trialId,
    fieldVarietyId,
  }: {
    trialId?: number | string;
    fieldVarietyId?: number | string;
  }) => {
    return useQuery<FieldVariety>({
      queryKey: ["field_variety", fieldVarietyId?.toString()],
      queryFn: () =>
        handleDetail({
          baseUrl: `trials/${trialId}/field_varieties`,
          id: fieldVarietyId,
        }),
      enabled: !!fieldVarietyId,
    });
  },
  useSave: () => {
    return useMutation({
      mutationFn: (fieldVarietyInput: FieldVarietyInput) =>
        handleSave<FieldVariety>({
          baseUrl: `trials/${fieldVarietyInput.trialId}/field_varieties`,
          input: {
            ...fieldVarietyInput,
            trialId: fieldVarietyInput.trialId,
          },
        }),
      onSuccess: async (data: FieldVariety) => {
        queryClient.setQueryData(
          ["field_varieties", data.trialId.toString()],
          (oldData: FieldVariety[] | undefined) => {
            if (oldData) {
              return unionBy([data], oldData, "id");
            } else {
              return [data];
            }
          }
        );
      },
    });
  },
  useDelete: () => {
    return useMutation({
      mutationFn: (fieldVariety: FieldVariety) =>
        handleDelete<{ id: string; trialId: string }>({
          baseUrl: `trials/${fieldVariety.trialId}/field_varieties`,
          id: fieldVariety.id,
        }),
      onSuccess: async ({ id, trialId }: { id: string; trialId: string }) => {
        queryClient.setQueryData(
          ["field_varieties", trialId],
          (oldData: FieldVariety[] | undefined) => {
            return (
              oldData?.filter(
                fieldVariety => fieldVariety.id !== parseInt(id)
              ) || []
            );
          }
        );
      },
    });
  },
};

export default methods;
