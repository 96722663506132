import * as Sentry from "@sentry/react";
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import UserApi from "../api/User.api";
import { UserInput } from "../types";
import UserConnections from "./UserConnections";
import { useSession } from "../contexts/Session.context";
import toast from "react-hot-toast";
import { HttpError } from "../types/http-error";

export default function UserForm({ user }: { user?: UserInput }) {
  const navigate = useNavigate();
  const { session } = useSession();

  const [firstName, setFirstName] = React.useState<string>(
    user?.firstName || ""
  );
  const [lastName, setLastName] = React.useState<string>(user?.lastName || "");
  const [email, setEmail] = React.useState<string>(user?.email || "");
  const [admin, setAdmin] = React.useState<boolean>(user?.admin || false);
  const [hideExtraTrialInfo, setHideExtraTrialInfo] = React.useState<boolean>(
    user?.hideExtraTrialInfo || false
  );

  const { mutateAsync, isLoading } = UserApi.useSave({
    ...user,
    firstName,
    lastName,
    email,
    admin,
    hideExtraTrialInfo,
  });

  const onSave = async () => {
    try {
      const res = await mutateAsync();
      navigate(`/users/${res.id}`);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to create User");
      }
    }
  };

  const handlePasswordReset = async () => {
    if (!user?.id) {
      return;
    }
    try {
      await UserApi.sendPasswordReset(user?.id);
      toast.success("Successfully sent password reset email.");
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2">
          {session?.userId === user?.id
            ? "My Profile"
            : user?.id
              ? "Edit User"
              : "Create User"}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          label="First Name"
          fullWidth
          value={firstName}
          onChange={event => setFirstName(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Last Name"
          fullWidth
          value={lastName}
          onChange={event => setLastName(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Email"
          fullWidth
          value={email}
          onChange={event => setEmail(event.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        {user?.id && <UserConnections userId={user?.id} />}
      </Grid>
      <Grid item xs={6} sm={3}>
        <FormControlLabel
          label="Admin"
          control={
            <Switch
              checked={admin}
              onChange={event => setAdmin(event.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <FormControlLabel
          label="Hide Extra Trial Info"
          control={
            <Switch
              checked={hideExtraTrialInfo}
              onChange={event => setHideExtraTrialInfo(event.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
        />
      </Grid>
      {user?.id && (
        <Grid item xs={12} sm={6}>
          <Button variant="text" onClick={handlePasswordReset}>
            Send reset password link to the above email?
          </Button>
        </Grid>
      )}
      <Grid item xs={12} sm={3}>
        <Button
          component={Link}
          to="/users"
          variant="contained"
          color="info"
          fullWidth
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button
          disabled={isLoading}
          fullWidth
          variant="contained"
          color="primary"
          onClick={onSave}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
}
