import { useInfiniteQuery, useMutation, useQuery } from "react-query";

import {
  Company,
  CompanyInput,
  MetaPagination,
  Param,
  ListResponseData,
  CompanyCategoryName,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleInfiniteList,
  handleList,
  handleSave,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useList: ({ query, page }: { query?: string; page?: number }) => {
    const params: Param[] = [];
    if (query) {
      params.push({
        key: "search",
        value: query,
      });
    }
    if (page) {
      params.push({
        key: "page",
        value: page,
      });
    }
    return useQuery<ListResponseData<Company>>({
      queryKey: ["companies", query?.toString(), page?.toString()],
      queryFn: () => handleList<Company>({ baseUrl: "companies", params }),
    });
  },
  useInfiniteList: (query?: string) => {
    const params: Param[] = [];
    if (query) {
      params.push({
        key: "search",
        value: query,
      });
    }

    return useInfiniteQuery({
      queryKey: ["companies"],
      queryFn: handleInfiniteList<Company>({ baseUrl: "companies", params }),
      // initialPageParam: 0,
      keepPreviousData: false,
      getNextPageParam: (lastPage, pages) => lastPage.meta.nextPage,
      getPreviousPageParam: (firstPage, pages) => firstPage.meta.prevPage,
    });
  },
  useListByCompanyCategory: ({
    query,
    companyCategoryName,
  }: {
    query: string;
    companyCategoryName: CompanyCategoryName;
  }) => {
    let companyCategoryUrl = "growers";
    if (companyCategoryName === "supplier") {
      companyCategoryUrl = "suppliers";
    } else if (companyCategoryName === "shipper") {
      companyCategoryUrl = "shippers";
    }
    const params: Param[] = [];
    if (query) {
      params.push({
        key: "search",
        value: query,
      });
    }
    return useQuery({
      queryKey: [companyCategoryName?.toString(), query?.toString()],
      queryFn: () =>
        handleSimpleList<Company>({
          url: `${companyCategoryUrl}`,
          params,
        }),
    });
  },
  useDetail: (id?: number | string) => {
    return useQuery({
      queryKey: ["company", id?.toString()],
      queryFn: () => handleDetail<Company>({ id, baseUrl: "companies" }),
      enabled: !!id,
    });
  },
  useSave: (input: CompanyInput) => {
    return useMutation<Company>({
      mutationFn: () => handleSave({ baseUrl: "companies", input }),
      // onError: handleMutationError(enqueueSnackbar),
      retry: 1,
      onSuccess: async data => {
        await queryClient.refetchQueries(["companies"]);
      },
    });
  },
  useDelete: (id: number | string) => {
    return useMutation<{ id: number }>({
      mutationFn: () => handleDelete({ baseUrl: "companies", id }),
      onSuccess: async ({ id }: { id: number }) => {
        queryClient.setQueryData(["companies"], (oldData: any) => {
          const newPages = oldData.pages.map(
            (group: { data: Company[]; meta: MetaPagination }) => ({
              data: group.data.filter(
                obj => obj.id?.toString() !== id?.toString()
              ),
              meta: group.meta,
            })
          );
          return {
            pages: newPages,
            pageParams: oldData.pageParams,
          };
        });
      },
    });
  },
  useSuppliersByTrialVarietiesAttachedToTrial: ({
    trialId,
  }: {
    trialId?: number | string;
  }) => {
    return useQuery({
      queryKey: ["suppliers_by_trial", trialId?.toString()],
      queryFn: () =>
        handleSimpleList<Company>({
          url: "companies/suppliers_by_trial_varieties_attached_to_trial",
          params: [{ key: "trial_id", value: trialId }],
        }),
    });
  },
};

export default methods;
