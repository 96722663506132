import * as Sentry from "@sentry/react";
import React from "react";
import { Measurement, Criterium } from "../../types";
import MeasurementApi from "../../api/Measurement.api";
import {
  Box,
  Modal,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TableHead,
  InputLabel,
  FormControl,
  MenuItem,
  Select
} from "@mui/material";
import toast from "react-hot-toast";
import { HttpError } from "../../types/http-error";

export default function LinkMeasurementsToCriteria({
  open,
  handleClose,
  criterium,
}: {
  open: boolean;
  handleClose: () => void;
  criterium: Criterium;
}) {
  const { data: measurements } = MeasurementApi.useList(criterium.commodityId);
  const { data: selectedCriteriumMeasurements, refetch } =
    MeasurementApi.useByCriteriumList({
      commodityId: criterium.commodityId,
      criteriumId: criterium.id,
    });
  const { mutateAsync: attachMeasurement } = MeasurementApi.useAddMeasurement({
    commodityId: criterium.commodityId,
    criteriumId: criterium.id,
  });
  const { mutateAsync: updateCriteriumMeasurement } =
    MeasurementApi.useUpdateMeasurement({
      commodityId: criterium.commodityId,
      criteriumId: criterium.id,
    });
  const { mutateAsync: removeMeasurement } =
    MeasurementApi.useDeleteMeasurement({
      commodityId: criterium.commodityId,
      criteriumId: criterium.id,
    });

  const handleToggleMeasurement =
    (measurement: Measurement) =>
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        if (
          selectedCriteriumMeasurements &&
          selectedCriteriumMeasurements
            .map(obj => obj.measurementId?.toString())
            .includes(measurement.id?.toString())
        ) {
          await removeMeasurement({ measurementId: measurement.id });
        } else {
          await attachMeasurement({ measurementId: measurement.id });
        }
        await refetch();
        toast.success("Successly saved");
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        if (error instanceof HttpError && error.message) {
          toast.error(error.message?.split(",").join("\n"));
        } else {
          toast.error("Failed to toggle checkmark.");
        }
      }
    };

  const handleUpdateLink =
    (measurement: Measurement) =>
    async (event: any) => {
      try {
        if (
          selectedCriteriumMeasurements &&
          selectedCriteriumMeasurements
            .map(obj => obj.measurementId?.toString())
            .includes(measurement.id?.toString())
        ) {
          const criteriumMeasurement = selectedCriteriumMeasurements.find(
            obj => obj.measurementId?.toString() === measurement.id?.toString()
          );

          if(criteriumMeasurement?.id){
            await updateCriteriumMeasurement({
              id: criteriumMeasurement.id,
              math: event.target.value,
            });
            toast.success("Successly saved");
          }
        }
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        if (error instanceof HttpError && error.message) {
          toast.error(error.message?.split(",").join("\n"));
        } else {
          toast.error("Failed to update math.");
        }
      }
    };

  console.log('selectedCriteriumMeasurements', selectedCriteriumMeasurements)

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox color="primary" disabled />
                </TableCell>
                <TableCell>Measurement Name</TableCell>
                <TableCell>Function to Apply</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {measurements &&
                measurements.map((measurement: Measurement) => (
                  <TableRow
                    key={measurement.id}
                    role="checkbox"
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={
                          selectedCriteriumMeasurements
                            ? selectedCriteriumMeasurements
                                .map(obj => obj.measurementId?.toString())
                                .includes(measurement.id?.toString())
                            : false
                        }
                        onChange={handleToggleMeasurement(measurement)}
                      />
                    </TableCell>
                    <TableCell>{measurement.name}</TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id="math-select-label">Math</InputLabel>
                        <Select
                          labelId="math-select-label"
                          id="math-select"
                          value={
                            selectedCriteriumMeasurements
                              ? selectedCriteriumMeasurements.find(
                                  obj =>
                                    obj.measurementId?.toString() ===
                                    measurement.id?.toString()
                                )?.math || "multiply"
                              : "multiply"
                          }
                          label="Math"
                          onChange={handleUpdateLink(measurement)}
                          disabled={
                            selectedCriteriumMeasurements
                              ? !selectedCriteriumMeasurements
                                  .map(obj => obj.measurementId?.toString())
                                  .includes(measurement.id?.toString())
                              : true
                          }
                        >
                          <MenuItem value={"multiply"}>Multiply</MenuItem>
                          <MenuItem value={"divide"}>Divide</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
}
