import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import RanchApi from "../api/Ranch.api";
import SimpleListName from "../components/SimpleListName";
import IndexHeaderButton from "../ui/IndexHeaderButton";
import { Ranch } from "../types";

export default function RanchList() {
  const navigate = useNavigate();
    const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem"}}>
       <Grid container spacing={2}>
        <Grid item xs={9} sm={8}>
          <Typography
            variant={isSmallScreen ? "h4" : "h2"}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            Ranches
          </Typography>
        </Grid>
        <Grid item xs={3} sm={4} sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end"}}>
          <IndexHeaderButton
            handleCreate={() => {
              navigate("/ranches/create");
            }}
          />
        </Grid>
      </Grid>
      <SimpleListName
        useList={RanchApi.useInfiniteList}
        useDelete={RanchApi.useDelete}
        baseUrl="ranches"
        headers={[
          { key: "name", label: "Name", getValue: (obj: Ranch) => obj.name },
        ]}
      />
    </Box>
  );
}
