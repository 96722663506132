import { Trial, TrialVariety, FieldVariety } from '../types'
import React from 'react'

type Props = {
  id?: string;
  trial: Trial;
  trialVarieties: TrialVariety[];
  children: JSX.Element;
}

interface ITrialContext {
  selectedFieldVariety?: FieldVariety;
  setSelectedFieldVariety: (arg: FieldVariety | undefined) => void;
  selectedTrialVariety?: TrialVariety;
  setSelectedTrialVariety: (arg: TrialVariety | undefined) => void;
}

const TrialContext = React.createContext<Partial<ITrialContext>>({});

export function TrialProvider({ id, trial, trialVarieties, children }: Props){
  const [selectedTrialVariety, setSelectedTrialVariety] = React.useState<TrialVariety | undefined>(undefined)
  const [selectedFieldVariety, setSelectedFieldVariety] = React.useState<FieldVariety | undefined>(undefined)

  return (
    <TrialContext.Provider
      value={{
        selectedFieldVariety,
        setSelectedFieldVariety: (arg: FieldVariety | undefined) => { setSelectedFieldVariety(arg); setSelectedTrialVariety(undefined) },
        selectedTrialVariety,
        setSelectedTrialVariety: (arg: TrialVariety | undefined) => { setSelectedTrialVariety(arg); setSelectedFieldVariety(undefined) },
      }}
    >
      {children}
    </TrialContext.Provider>
  )
}

export function useTrialContext(){
  return React.useContext(TrialContext)
}