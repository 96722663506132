import React from "react";
import * as Sentry from "@sentry/react";
import { FieldVariety, PlotBed, PlotType, TrialVariety } from "../../types";
import PlotBedApi from "../../api/PlotBed.api";
import { createPlotBedByLocation, getPlotNumbersByPlotId } from "../../utils";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import TrialVarietyApi from "../../api/TrialVariety.api";
import { isEmpty } from "lodash";
import toast from "react-hot-toast";

export default function AttachTrialVarietyToFieldVariety({
  fieldVariety,
  plotType,
  plotRows,
  plotCols,
}: {
  fieldVariety: FieldVariety;
  plotType: PlotType;
  plotRows: number;
  plotCols: number;
}) {
  const { data: trialVarieties } = TrialVarietyApi.useList(
    fieldVariety.trialId
  );
  const { data: plotBeds } = PlotBedApi.useList({
    parentId: fieldVariety.trialId,
    parentType: "trial",
  });

  const { mutateAsync: saveTrialVariety, isLoading } =
    TrialVarietyApi.useSave();

  const plotBedByLocation: { [key: string]: PlotBed } =
    createPlotBedByLocation(plotBeds);

  const plotNumbersByPlotId = getPlotNumbersByPlotId({
    plotBeds: plotBedByLocation,
    plotType,
    rows: plotRows,
    cols: plotCols,
  });

  const handleFieldVarietySelect =
    (trialVariety: TrialVariety) =>
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        await saveTrialVariety({
          ...trialVariety,
          fieldVarietyId: event.target.checked ? fieldVariety.id : null,
        });
        toast.success("Successfully saved.");
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        toast.error("Failed to save selection");
      }
    };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        flexWrap: "wrap",
      }}
    >
      {trialVarieties &&
        !isEmpty(trialVarieties) &&
        trialVarieties
          .sort((a, b) => plotNumbersByPlotId[a.id] - plotNumbersByPlotId[b.id])
          .map(trialVariety => (
            <Box sx={{ my: 1 }} key={trialVariety.id}>
              <FormControlLabel
                label={`${plotNumbersByPlotId[trialVariety.id]} ${
                  trialVariety.variety.name
                }`}
                control={
                  <Checkbox
                    checked={trialVariety.fieldVarietyId === fieldVariety.id}
                    onChange={handleFieldVarietySelect(trialVariety)}
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={isLoading}
                  />
                }
              />
            </Box>
          ))}
    </Box>
  );
}
