import { useMutation, useQuery } from "react-query";
import { Measurement, MeasurementInput, CriteriaMeasurement, CriteriaMeasurementMath } from '../types'
import queryClient from "./queryClient";
import { handleDelete, handleSave, handleSimpleList } from "./handler";
import { unionBy } from 'lodash'

const methods = {
  useList: (commodityId?: number | string) => {
    return useQuery({
      enabled: !!commodityId,
      queryKey: ["measurements", commodityId?.toString()],
      queryFn: () =>
        handleSimpleList<Measurement>({
          url: `commodities/${commodityId}/measurements`
        })
    });
  },
  useSave: () => {
    return useMutation({
      mutationFn: (measurement: MeasurementInput) =>
        handleSave<Measurement>({
          baseUrl: `commodities/${measurement.commodityId}/measurements`,
          input: measurement
        }),
      onSuccess: async (measurement: Measurement) => {
        queryClient.setQueryData(
          ["measurements", measurement.commodityId.toString()],
          (oldData: any) => {
            if(oldData){
              return unionBy([measurement], oldData, 'id')
            }
            return [measurement]
          }
        );
      }
    })
  },
  useDelete: ({ commodityId }: { commodityId?: number | string }) => {
    return useMutation({
      mutationFn: (id: number) =>
        handleDelete<{ id: number }>({
          baseUrl: `commodities/${commodityId}/measurements`,
          id,
        }),
      onSuccess: async ({ id }: { id: number }) => {
        queryClient.setQueryData(
          ["measurements", commodityId?.toString()],
          (oldData: any) => {
            if (oldData && Array.isArray(oldData)) {
              return oldData.filter(
                measurement => measurement.id.toString() !== id.toString()
              );
            }
            return oldData;
          }
        );
      },
    });
  },
  useByCriteriumList: ({ commodityId, criteriumId }: { commodityId: number | string, criteriumId: number | string }) => {
    return useQuery<CriteriaMeasurement[]>({
      enabled: !!commodityId,
      queryKey: ["measurements_by_criterium", commodityId.toString(), criteriumId.toString()],
      queryFn: () =>
        handleSimpleList<CriteriaMeasurement>({
          url: `commodities/${commodityId}/criteria/${criteriumId}/criteria_measurements`
        }),
    });
  },
  useAddMeasurement: ({ commodityId, criteriumId }: { commodityId: number | string, criteriumId: number | string }) => {
    return useMutation({
      mutationFn: ({  measurementId }: { measurementId: number | string }) =>
        handleSave<CriteriaMeasurement>({
          baseUrl: `commodities/${commodityId}/criteria/${criteriumId}/create_measurement`,
          input: { measurementId }
        }),
      onSuccess: async (criteriaMeasurement: CriteriaMeasurement) => {
        queryClient.setQueryData(
          ["measurements_by_criterium", commodityId.toString(), criteriumId.toString()],
          (oldData: any) => {
            if (oldData && Array.isArray(oldData)) {
              oldData.push(criteriaMeasurement)
              return [...oldData]
            }
            return oldData;
          }
        );
      },
    })
  },
  useUpdateMeasurement: ({ commodityId, criteriumId }: { commodityId: number | string, criteriumId: number | string }) => {
    return useMutation({
      mutationFn: ({ id, math }: { id: number | string, math: CriteriaMeasurementMath }) =>
        handleSave<CriteriaMeasurement>({
          baseUrl: `commodities/${commodityId}/criteria/${criteriumId}/update_criterium_measurement`,
          input: { criteriaMeasurementId: id, math }
        }),
      onSuccess: async (criteriaMeasurement: CriteriaMeasurement) => {
        queryClient.setQueryData(
          ['measurements_by_criterium', commodityId.toString(), criteriumId.toString()],
          (oldData: any) => {
            if (oldData && Array.isArray(oldData)) {
              return unionBy([criteriaMeasurement], oldData, "id");
            }
            return oldData;
          }
        )
      }
    })
  },
  useDeleteMeasurement: ({ commodityId, criteriumId }: { commodityId: number | string, criteriumId: number | string }) => {
    return useMutation({
      mutationFn: ({ measurementId }: { measurementId: number | string }) =>
        handleSave<{ measurementId: number }>({
          baseUrl: `commodities/${commodityId}/criteria/${criteriumId}/delete_measurement`,
          input: { measurementId }
        }),
      onSuccess: async ({ measurementId }: { measurementId: number | string }) => {
        queryClient.setQueryData(
          ["measurements_by_criterium", commodityId.toString(), criteriumId.toString()],
          (oldData: any) => {
            if (oldData && Array.isArray(oldData)) {
              return oldData.filter(
                criteriaMeasurement => criteriaMeasurement.criteriumId.toString() !== criteriumId.toString()
              );
            }
            return oldData;
          }
        );
      },
    })
  }
}
export default methods;