import { useMutation, useQuery } from "react-query";
import { EvaluableType, MeasurementEvaluation, MeasurementEvaluationInput, Param } from "../types";
import { handleSave, handleSimpleList } from "./handler";
import queryClient from "./queryClient";
import { isEmpty, unionBy } from "lodash";

const methods = {
  useList: ({
    measurementEvaluableType,
    measurementEvaluableId,
  }: {
    measurementEvaluableType: EvaluableType;
    measurementEvaluableId: number;
  }) => {
    const params: Param[] = [];
    params.push({
      key: "measurement_evaluable_id",
      value: measurementEvaluableId,
    });
    params.push({
      key: "measurement_evaluable_type",
      value: measurementEvaluableType,
    });
    return useQuery<MeasurementEvaluation[]>({
      queryKey: ["measurement_evaluations", measurementEvaluableType, measurementEvaluableId?.toString()],
      queryFn: () =>
        handleSimpleList<MeasurementEvaluation>({ url: "measurement_evaluations", params }),
    });
  },
  useSave: () => {
    return useMutation({
      mutationFn: ({
        measurementEvaluationInput,
        measurementEvaluableId,
        measurementEvaluableType,
      }: {
        measurementEvaluationInput: MeasurementEvaluationInput;
        measurementEvaluableType: EvaluableType;
        measurementEvaluableId: number;
      }) =>
        handleSave<MeasurementEvaluation>({
          baseUrl: "measurement_evaluations",
          input: { ...measurementEvaluationInput, measurementEvaluableType, measurementEvaluableId },
        }),
      onSuccess: async (data: MeasurementEvaluation) => {
        const measurementEvaluableType = data.measurementEvaluableType;
        const measurementEvaluableId = data.measurementEvaluableId;
        queryClient.setQueryData(
          ["measurement_evaluations", measurementEvaluableType, measurementEvaluableId.toString()],
          (oldData: MeasurementEvaluation[] | undefined) => {
            if (oldData && !isEmpty(oldData)) {
              return unionBy([data], oldData, "id");
            } else {
              return [data];
            }
          }
        );
      },
    })
  }
}
export default methods;