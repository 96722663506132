import { Button, Grid, TextField, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import CompanyApi from "../api/Company.api";
import { CompanyCategory, CompanyInput, Person, Region } from "../types";
import AssociatedRegions from "./AssociatedRegions";
import AssociatedCompanyCategories from "./AssociatedCompanyCategories";
import AssociatedPeople from "./AssociatedPeople";
import { HttpError } from "../types/http-error";
import toast from "react-hot-toast";

export default function CompanyForm({ company }: { company?: CompanyInput }) {
  const navigate = useNavigate();
  const [name, setName] = React.useState<string>(company?.name || "");
  const [address, setAddress] = React.useState<string>(company?.address || "");
  const [address2, setAddress2] = React.useState<string>(
    company?.address2 || ""
  );
  const [city, setCity] = React.useState<string>(company?.city || "");
  const [state, setState] = React.useState<string>(company?.state || "");
  const [country, setCountry] = React.useState<string>(company?.country || "");
  const [zipcode, setZipcode] = React.useState<string>(company?.zipcode || "");
  const [phoneNumber, setPhoneNumber] = React.useState<string>(
    company?.phoneNumber || ""
  );
  const [faxNumber, setFaxNumber] = React.useState<string>(
    company?.faxNumber || ""
  );
  const [website, setWebsite] = React.useState<string>(company?.website || "");

  const [companyCategories, setCompanyCategories] = React.useState<
    CompanyCategory[]
  >(company?.companyCategories || []);
  const [people, setPeople] = React.useState<Person[]>(company?.people || []);
  const [regions, setRegions] = React.useState<Region[]>(
    company?.regions || []
  );

  const { mutateAsync, isLoading } = CompanyApi.useSave({
    id: company?.id,
    name,
    address,
    address2,
    city,
    state,
    country,
    zipcode,
    phoneNumber,
    faxNumber,
    website,
    companyCategoryIds: companyCategories.map(category => category.id),
    regionIds: regions.map(region => region.id),
    personIds: people.map(person => person.id),
  });

  const onSave = async () => {
    try {
      const res = await mutateAsync();
      navigate(`/companies/${res.id}`);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to create Person");
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2">
          {company?.id ? "Edit Company" : "Create Company"}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label={"Name"}
          value={name}
          onChange={event => setName(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AssociatedRegions
          selectedRegions={regions}
          setSelectedRegions={(regions: Region[]) => setRegions(regions)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AssociatedCompanyCategories
          selectedCompanyCategories={companyCategories}
          setSelectedCompanyCategories={setCompanyCategories}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label={"Address"}
          value={address}
          onChange={event => setAddress(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label={"Apt/Suite #"}
          value={address2}
          onChange={event => setAddress2(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label={"City"}
          value={city}
          onChange={event => setCity(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label={"State"}
          value={state}
          onChange={event => setState(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label={"Country"}
          value={country}
          onChange={event => setCountry(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label={"Zip Code"}
          value={zipcode}
          onChange={event => setZipcode(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label={"Website"}
          value={website}
          onChange={event => setWebsite(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label={"Phone Number"}
          value={phoneNumber}
          onChange={event => setPhoneNumber(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label={"Fax Number"}
          value={faxNumber}
          onChange={event => setFaxNumber(event.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        {/*  PEOPLE SELECT */}
        <AssociatedPeople
          selectedPeople={people}
          setSelectedPeople={setPeople}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          fullWidth
          component={Link}
          to="/companies"
          variant="contained"
          color="secondary"
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          disabled={isLoading}
          fullWidth
          variant="contained"
          color="primary"
          onClick={onSave}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
}
