import StatisticsApi from "../../api/Statistics.api";
import { Typography } from "@mui/material";
import dayjs from 'dayjs'
import { Trial } from "../../types";

export default function RecommendHarvestDate({ trial }: { trial: Trial}){
  const { data } = StatisticsApi.useEstHarvestDate({
    commodityId: trial.commodityId,
    culturalPractice: trial.culturalPractice,
    dateType: trial.transplantDate ? "transplant" : "wet",
    searchDate: trial.transplantDate ? dayjs(trial.transplantDate).toDate() : dayjs(trial.wetDate).toDate()
  })

  let date = "Not Available"
  if(data && data?.tempEstimatedHarvestDate){
    date = dayjs(data.tempEstimatedHarvestDate).format("MMM D, YYYY")
  }

  return (
    <>
      <Typography variant="caption">Recommended Harvest Date {date}</Typography>
    </>
  )
}